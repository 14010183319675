import { AnyPropsType } from '~/AnyPropsType';
import cx from 'classnames';
import React from 'react';

import s from './styles.module.scss';
import { Button } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const DownloadButton = ({
  percentComplete,
  onClick,
  downloadingStatus,
  isFullDownloadingProcessFailed,
  className,
  label,
}: AnyPropsType) => (
  <Button
    variant="outlined"
    type="button"
    color="primary"
    className={cx(s.btn, className, downloadingStatus === 'started' && !isFullDownloadingProcessFailed && s.loading)}
    onClick={onClick}
    size="medium"
    startIcon={!(downloadingStatus === 'started' && !isFullDownloadingProcessFailed) && <FileDownloadOutlinedIcon />}
  >
    <div
      className={cx(
        s.btnProgress,
        (downloadingStatus === 'finished' || isFullDownloadingProcessFailed) && s.translateLeft,
      )}
      style={{ width: `${percentComplete}%` }}
    />
    <div
      className={cx(s.btnText, downloadingStatus === 'started' && !isFullDownloadingProcessFailed && s.translateRight)}
    >
      {downloadingStatus === 'started' && !isFullDownloadingProcessFailed ? `${percentComplete}%` : label}
    </div>
  </Button>
);

// DownloadButton.propTypes = {
//   percentComplete: PropTypes.number,
//   onClick: PropTypes.func.isRequired,
//   children: PropTypes.node.isRequired,
//   downloadingStatus: PropTypes.string.isRequired,
//   isFullDownloadingProcessFailed: PropTypes.bool.isRequired,
// };

DownloadButton.defaultProps = {
  percentComplete: 0,
  className: null,
};
export { DownloadButton };
