import React, { useEffect, useState } from 'react';
import { onPaginationHelper } from '~/app/utils/pagination-helpers';
import { actions, selectors } from '~/data/index.js';
import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';
import { useParams, useHistory } from 'react-router-dom';
import { particlesPagePath } from '~/app/constants/url/auditor';
import { formatDate } from '~/app/utils/format-data';
import ReactTooltip from 'react-tooltip';
import { Row, Text } from '~/ui/index.js';
import {
  AlertColor,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import ReactDOMServer from 'react-dom/server';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';
import TablePagination from '~/ui/components/table-pagination/index';
import Toast from '~/ui/components/toast/index';

import s from './styles.module.scss';

export const ParticlesUpdates = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { page } = useParams<{ page: string }>();
  const [updateJobId, setUpdateJobId] = useState(null as string);

  const particlesAuditLogs: { count: number; items: any } = useAppSelector(selectors.internal.getLogs);
  const isParticlesAuditLogsLoaded = useAppSelector(selectors.internal.logsLoaded);
  const isParticlesUpdateLoading = useAppSelector(selectors.internal.particlesUpdateLoading);

  const [perPage, setPerPage] = useState(10);
  const [toastInfo, setToastInfo] = useState(null as { type: AlertColor; message: string });

  const fetchAuditLogs = (filter) => dispatch(actions.internal.fetchAuditLogs(filter));
  const fetchJobStatus = (id) => dispatch(actions.internal.fetchJobStatus(id));
  const clear = () => dispatch(actions.internal.clear());
  const updateEndpoint = () => dispatch(actions.internal.updatePackages());

  const queryParams = {
    'paging.Limit': perPage,
    'paging.Offset': Number(page) > 1 ? (Number(page) - 1) * perPage : 0,
    type: 'ParticlesVersionUpdate',
  };

  useEffect(() => {
    fetchAuditLogs(queryParams);
    return () => {
      clear();
    };
  }, [page, perPage]);

  const onPagination = (e) => {
    setPerPage(e.target.value);
    onPaginationHelper(e.target);
  };

  const handleUpdateEndpoint = async () => {
    const res = await updateEndpoint();
    if (res?.id) {
      setUpdateJobId(res.id);
      setToastInfo({
        message: 'Update started. Please note that this may take up to 2 hours.',
        type: 'success',
      });
      const timer = setInterval(async () => {
        const statusRes = await fetchJobStatus(res.id);
        if (statusRes.status !== 'Succeeded' && statusRes.status !== 'Failed') return;
        setToastInfo({
          message: statusRes.status === 'Succeeded' ? 'Update succeeded' : 'Update failed',
          type: statusRes.status === 'Succeeded' ? 'success' : 'error',
        });
        fetchAuditLogs(queryParams);
        setUpdateJobId(null);
        clearInterval(timer);
      }, 5000);
    }
  };
  return (
    <>
      <div className={s.imports}>
        <Box margin="32px 0 18px">
          <Text size={Text.size.s} weight={Text.weight.medium}>
            BR-AG Particles Updates
          </Text>
        </Box>
        <Row className={s.importsRow}>
          <Button
            variant="contained"
            onClick={handleUpdateEndpoint}
            data-tip={ReactDOMServer.renderToString(
              <Text size={Text.size.xs} color={Text.color.white} className={s.updateEndpointMsg}>
                Please note that it may take up to 2 hours to update the endpoint.
              </Text>,
            )}
            data-for="message"
            data-html
            loading={updateJobId || isParticlesUpdateLoading}
            disabled={updateJobId || isParticlesUpdateLoading}
          >
            <ReactTooltip id="message" />
            <Text className={s.updateEndpointMsg} size={Text.size.xs}>
              Call BR-AG Update Endpoint
            </Text>
          </Button>
        </Row>

        <Box marginTop="20px">
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="10%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        Date
                      </Text>
                    </TableCell>
                    <TableCell width="10%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        Status
                      </Text>
                    </TableCell>
                    <TableCell width="15%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        Old Version
                      </Text>
                    </TableCell>
                    <TableCell width="15%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        New Version
                      </Text>
                    </TableCell>
                    <TableCell width="15%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        User
                      </Text>
                    </TableCell>
                    <TableCell width="35%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        Response
                      </Text>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isParticlesAuditLogsLoaded ? (
                    <>
                      {particlesAuditLogs?.items?.length ? (
                        particlesAuditLogs.items.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell width="10%">
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {formatDate(item.inserted)}
                              </Text>
                            </TableCell>
                            <TableCell width="15%">
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {item.context.Status}
                              </Text>
                            </TableCell>
                            <TableCell width="15%">
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {item.context.OldVersion}
                              </Text>
                            </TableCell>
                            <TableCell width="15%">
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {item.context.NewVersion}
                              </Text>
                            </TableCell>
                            <TableCell width="15%">
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {item.executorUser?.firstName} {item.executorUser?.familyName}
                              </Text>
                            </TableCell>
                            <TableCell
                              width="35%"
                              data-tip={ReactDOMServer.renderToString(
                                <Text size={Text.size.xs} color={Text.color.white} className={s.updateEndpointMsg}>
                                  <strong>Headers:</strong>
                                  <br />
                                  {JSON.parse(item.context.Headers).map((x) => (
                                    <>
                                      {x.Name}: {x.Value} <br />
                                    </>
                                  ))}
                                </Text>,
                              )}
                              data-for="headers"
                              data-html
                            >
                              <ReactTooltip id="headers" />
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {item.context.Content || 'empty'}
                              </Text>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Text>No audit logs found</Text>
                      )}
                    </>
                  ) : (
                    <TableRowsLoader rowsNum={perPage} colsNum={6} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {isParticlesAuditLogsLoaded && !!particlesAuditLogs?.items?.length && (
              <div className={s.importsPagination}>
                <TablePagination
                  totalItems={particlesAuditLogs?.count}
                  perPage={perPage}
                  page={page}
                  handleChangePage={(p) => {
                    history.push(particlesPagePath(p + 1));
                  }}
                  onRowsPerPageChange={onPagination}
                />
              </div>
            )}
          </Paper>
        </Box>
      </div>
      <Toast
        open={!!toastInfo}
        handleClose={() => setToastInfo(null)}
        message={toastInfo?.message}
        type={toastInfo?.type}
      />
    </>
  );
};
