/* eslint-disable jsx-a11y/anchor-is-valid */
import { AnyPropsType } from '~/AnyPropsType';
import Loader from '~/app/components/core/preloader';
import { scopeLabels } from '~/app/constants/app/options';
import { reportPath } from '~/app/constants/url/auditor';
import { formatDate } from '~/app/utils/format-data';
import { Row } from '~/ui/index.js';
import {
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import s from './styles.module.scss';
import { useGetReports } from '~/data/reports/queries';
import { scopeTypeMapper } from './UploadReportModal';
import { Show } from '~/app/components/core/show';
import { ReportLifecycleStatus, ReportProcessingOutcome } from '~/data/openapi-client';
import { useGetClientScopes } from '~/data/clients/queries';
import cx from '~/data/utils/helpers/cx';

const transformStatus = (status: ReportProcessingOutcome) => {
  if (status === ReportProcessingOutcome.NotProcessed) return 'Draft';
  return status;
};

const DirectionMapper = {
  ASC: 'asc',
  DESC: 'desc',
};

const Reports = ({
  client,
  sort,
  onSort,
  onFilter,
  isClientLoaded,
  toggleReportRemovePopup,
  toggleEditVatPopup,
  toggleUploadPopup,
  queryParams,
  setQueryParams,
}: AnyPropsType) => {
  const { clientId = '' } = useParams<{ clientId: string }>();

  const {
    data: reportsRes,
    isSuccess: isReportsLoaded,
    isLoading: isReportsLoading,
  } = useGetReports(clientId, queryParams, {
    refetchOnWindowFocus: true,
  });

  const { data: scopeTypes, isSuccess: isScopesLoaded } = useGetClientScopes(clientId);

  const reports = reportsRes?.items ?? [];

  const options = isScopesLoaded
    ? scopeTypes.map((scopeType) => ({ value: scopeType, label: scopeLabels[scopeType] }))
    : [];

  const scopeOptions = [...options, { value: undefined, label: 'All' }];

  return isClientLoaded ? (
    <div className={s.reports}>
      <Row className={s.reportsRow}>
        <Typography component="h1" fontSize={24} className="text-dark">
          {client?.name}
        </Typography>
      </Row>
      <Row className="flex gap-1">
        <Typography fontSize={16} className="text-light">
          {client?.vatNumber}
        </Typography>

        <button onClick={() => toggleEditVatPopup(client?.vatNumber || true)}>
          <Typography className="text-orange">{client?.vatNumber ? `Edit` : 'Add VAT number'}</Typography>
        </button>
      </Row>
      <Row className="flex justify-between mt-8">
        <FormControl className="w-[250px]">
          <InputLabel>Scope</InputLabel>
          <Select
            className="w-full"
            value={queryParams.scope}
            onChange={(e) => {
              setQueryParams({ scope: e.target.value });
            }}
            label="Scope"
            disabled={options.length === 0}
          >
            {scopeOptions?.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div>
          <Button
            className="px-4 py-3 text-base max-h-12"
            variant="outlined"
            data-cy="clientUploadNav"
            onClick={toggleUploadPopup}
            startIcon={<FileUploadOutlinedIcon fontSize="inherit" />}
          >
            Upload regulatory report
          </Button>
        </div>
      </Row>

      <Row className={s.reportsCards}>
        <Paper classes={{ root: 'rounded-lg' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="11%" key="periodEnd" sortDirection={sort}>
                    <Typography fontSize={16} className="text-medium ml-8">
                      Period End
                    </Typography>
                  </TableCell>
                  <TableCell width="37%" key="scope">
                    <Typography fontSize={16} className="text-medium ml-8">
                      Scope
                    </Typography>
                  </TableCell>
                  <TableCell width="11%" key="status">
                    <Typography fontSize={16} className="text-medium ml-8">
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell width="11%" key="inserted">
                    <TableSortLabel
                      active
                      direction={DirectionMapper[sort.direction]}
                      onClick={() => onSort('Inserted', sort.direction === 'DESC' ? 'ASC' : 'DESC')}
                    >
                      <Typography fontSize={16} className="text-medium ml-8">
                        Created at
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="10%" key="actions">
                    <Typography fontSize={16} className="text-medium ml-8">
                      Actions
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.length === 0 && isReportsLoaded && (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography fontSize={16} className="ml-8">
                        no records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {isReportsLoaded ? (
                  reports?.map((report) => (
                    <TableRow
                      component={Link}
                      to={reportPath(client?.id ?? '', report.id)}
                      className={s.rowLink}
                      key={report.id}
                      hover={true}
                    >
                      <TableCell>
                        <Typography fontSize={16} className="text-medium ml-8">
                          {report.periodEnd && formatDate(report.periodEnd)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={16} className="text-medium ml-8">
                          {report.scopeType && scopeTypeMapper[report.scopeType]}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography fontSize={16} className="text-medium ml-8">
                          <Chip
                            label={
                              report.lifecycleStatus === ReportLifecycleStatus.Locked
                                ? report.lifecycleStatus
                                : transformStatus(report.processingOutcome)
                            }
                            className={cx(
                              (() => {
                                if (report.lifecycleStatus === ReportLifecycleStatus.Locked) return 'bg-status-warning';
                                if (report.processingOutcome === ReportProcessingOutcome.NotProcessed) return 'bg-grey';
                                if (report.processingOutcome === ReportProcessingOutcome.Processing)
                                  return 'bg-primary-blue';
                                if (report.processingOutcome === ReportProcessingOutcome.Succeeded)
                                  return 'bg-status-success';
                                if (report.processingOutcome === ReportProcessingOutcome.Failed)
                                  return 'bg-status-error';
                                return 'bg-grey';
                              })(),
                              'text-white',
                            )}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={16} className="text-medium ml-8">
                          {formatDate(report.inserted)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Remove" followCursor>
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              toggleReportRemovePopup(report);
                            }}
                            size="small"
                            className="ml-6"
                          >
                            <DeleteOutlineIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRowsLoader rowsNum={queryParams.limit} colsNum={5} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Show when={queryParams.limit < (reportsRes?.count ?? 0) || isReportsLoading}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={reportsRes?.count ?? 0}
              rowsPerPage={queryParams.limit}
              page={queryParams.offset / queryParams.limit}
              onPageChange={(e, page) => {
                setQueryParams({
                  offset: page * queryParams.limit,
                });
              }}
              onRowsPerPageChange={(e) => {
                setQueryParams({
                  limit: +e.target.value,
                });
              }}
            />
          </Show>
        </Paper>
      </Row>
    </div>
  ) : (
    <Loader />
  );
};

export default Reports;
