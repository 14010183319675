import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import hoursToMilliseconds from 'date-fns/hoursToMilliseconds';
import { queryKeyBuilder } from '~/data/utils/helpers/query-key.builder';
import { ClientComparisonApiAxiosParamCreator, ComparisonRow } from '~/data/openapi-client/index';
import { api, apiConfig, dazzlerConfiguration } from '~/data/dazzler-api';
import ky from 'ky';
import { countComparisons, createQuery, type Filters, listComparisons, registerComparisonFile } from './db';
import { logger } from '~/logger';
import { ListRepresentation } from '~/data/utils/types';
import { connection, db } from '~/db';

export const downloadComparisonRowsKey = (clientId: string, comparisonId: string) =>
  queryKeyBuilder('comparison-rows', 'client', clientId, 'comparisons', comparisonId, 'db-parquet-rows');

export const useDownloadComparisonRows = (
  clientId: string,
  comparisonId: string,
  options?: Omit<UseQueryOptions<Uint8Array>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryKey: downloadComparisonRowsKey(clientId, comparisonId),
    queryFn: async function downloadComparisonRows({ signal }) {
      const { url } = await ClientComparisonApiAxiosParamCreator(dazzlerConfiguration).downloadComparisonRowsParquet(
        clientId,
        comparisonId,
      );
      const endpoint = new URL(url, apiConfig.baseURL);
      const response = await ky(endpoint, {
        headers: {
          Authorization: api.getAuthorizationHeader() ?? '',
        },
        retry: {
          limit: 3,
        },
        signal,
      });

      const bytes = new Uint8Array(await response.arrayBuffer());
      const tableName = await registerComparisonFile(bytes, comparisonId);
      // disabled log for veracode
      // logger.info('[ComparisonRows] downloaded the data and inserted into the %s table', tableName);

      return bytes;
    },
    staleTime: hoursToMilliseconds(8),
    ...options,
  });

export const comparisonRowsQueryKey = (clientId: string, comparisonId: string, filters: Filters) =>
  queryKeyBuilder('comparison-rows', 'client', clientId, 'comparisons', comparisonId, 'rows', filters);

export function useFetchComparisonRows(
  clientId: string,
  comparisonId: string,
  filters: Filters,
  options?: Omit<
    UseQueryOptions<
      ListRepresentation<
        ComparisonRow & {
          RelativeDifference: number;
          RelativeDifferencePercent: number;
        }
      >
    >,
    'queryKey' | 'queryFn'
  >,
) {
  const query = createQuery(db, comparisonId, filters);

  return useQuery({
    queryKey: comparisonRowsQueryKey(clientId, comparisonId, filters),
    queryFn: async () => {
      const [items, count] = await Promise.all([
        listComparisons(connection, query.select),
        countComparisons(connection, query.count),
      ]);
      return {
        items,
        count,
      };
    },
    ...options,
  });
}
