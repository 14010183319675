import * as Yup from 'yup';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { z } from 'zod';
import { CountryCode } from '../openapi-client';

const vatRegex =
  /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|↵(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|↵(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|↵(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|↵(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|↵(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|↵(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|↵(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;

export const zodDay = z.custom<Dayjs>((val) => val instanceof dayjs, 'Invalid date');

export const ClientSchema = Yup.object().shape({
  vatNumber: Yup.string().matches(vatRegex, 'Invalid VAT number').required('Required'),
});

export const UserSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  familyName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
});

export const ClientCreationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  vatNumber: Yup.string().matches(vatRegex, 'Invalid VAT number').required('Required'),
  countryCode: Yup.string().required('Required'),
});

export const ReportRejectSchema = Yup.object().shape({
  rejectionMessage: Yup.string().required('Required'),
});

export const ClientMaterialitySchema = Yup.object().shape({
  materiality: Yup.object().shape({
    bank: Yup.number()
      .positive('Must be a positive number')
      .typeError('Must be a number')
      .nullable()
      .required('Required'),
    controllingCompany: Yup.number().positive('Must be a positive number').typeError('Must be a number').nullable(),
    consolidated: Yup.number().positive('Must be a positive number').typeError('Must be a number').nullable(),
  }),
});
