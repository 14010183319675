import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { queryKeyBuilder } from '../utils/helpers/query-key.builder';
import {
  ApplyActionToAllValidationChecksValidator,
  AuditLogRepresentation,
  type AuditLogSort,
  type DownloadRepresentation,
  FlagValidationChecksValidator,
  MarkValidationChecksBelowThresholdValidator,
  MarkValidationChecksPendingValidator,
  type ReportFileRepresentation,
  ReportingEngineProgressRepresentation,
  ReportsApi,
  ReportScopeRepresentation,
  type ReportScopeSort,
  ReportScopeValidator,
  ReviewedValidationCheckRepresentation,
  type ReviewedValidationCheckSort,
  ScopeType,
  ValidationCheckReviewStatus,
  VersionRepresentation,
  type VersionSort,
  WaiveValidationChecksValidator,
} from '../openapi-client/api';
import minutesToMilliseconds from 'date-fns/minutesToMilliseconds';
import {
  FilteredListRepresentation,
  Frameworks,
  ListRepresentation,
  PagingParams,
  SortingParams,
} from '../utils/types';
import { AxiosError, type AxiosProgressEvent } from 'axios';
import ky, { Options as KyOptions } from 'ky';
import { baseURL } from '~/config';
import { downloadFile, getFilename } from '~/data/downloads/file';
import { api, dazzler, type EndpointResponse } from '~/data/dazzler-api';

export const reportKeys = (() => {
  const base = 'reports';
  return {
    base: () => queryKeyBuilder(base),
    report: (clientId: string, reportId: string) => queryKeyBuilder(base, clientId, 'report', reportId),
    create: () => queryKeyBuilder(base, 'create'),
    delete: (clientId: string, reportId: string) => queryKeyBuilder(base, clientId, reportId, 'delete'),
    reports: (clientId: string, params?: ListReportsParams) => queryKeyBuilder(base, clientId, 'reports', params),
    reportingFrameworks: (clientId: string, reportId: string) =>
      queryKeyBuilder(base, clientId, reportId, 'reportingFrameworks'),
    uploadFile: () => queryKeyBuilder(base, 'file-upload'),
    fileList: (clientId: string, reportId: string, params?: ListReportFilesParams) =>
      queryKeyBuilder(base, 'clients', clientId, 'reports', reportId, 'files', params),
    startWorkflow: (clientId: string, reportId: string) => queryKeyBuilder(base, clientId, reportId, 'startWorkflow'),
    logs: (clientId: string, reportId: string, params?: ReportLogsParams) =>
      queryKeyBuilder(base, clientId, reportId, params, 'logs'),
    version: (clientId: string, reportId: string, version: string) =>
      queryKeyBuilder(base, clientId, reportId, 'version', version),
    versionList: (clientId: string, reportId: string, params: ListVersionsParams) =>
      queryKeyBuilder(base, 'clients', clientId, 'reports', reportId, 'versions', params),
    validationChecks: (clientId: string, reportId: string, versionId: string, params?: ValidationChecksParams) =>
      queryKeyBuilder(base, clientId, reportId, versionId, 'validationChecks', params),
    reportingEngineProgress: (clientId: string, reportId: string) =>
      queryKeyBuilder(base, clientId, reportId, 'reportingEngineProgress'),
    markChecks: (
      clientId: string,
      reportId: string,
      versionId: string,
      type: 'waive' | 'pending' | 'flag' | 'belowThreshold',
    ) => queryKeyBuilder(base, clientId, reportId, versionId, 'markChecks', type),
    listDownloads: (clientId: string, reportId: string) =>
      queryKeyBuilder(base, 'client', clientId, 'report', reportId, 'downloads'),
    listVersionVisualExcel: (clientId: string, reportId: string, versionId: string) =>
      queryKeyBuilder(base, 'client', clientId, 'reports', reportId, 'versions', versionId, 'visual-excels'),
    lockReport: (clientId: string, reportId: string) => queryKeyBuilder(base, clientId, reportId, 'lockReport'),
    applyActionToAllChecks: (clientId: string, reportId: string, versionId: string) =>
      queryKeyBuilder(base, clientId, reportId, versionId, 'applyActionToAllChecks'),
    listReportFileUploadProgresses: (clientId: string, reportId: string) =>
      queryKeyBuilder(base, 'client', clientId, 'reports', reportId, 'file-upload-progresses'),
    listVersionFilesReadyForProcessing: (clientId: string, reportId: string, versionId: string) =>
      queryKeyBuilder(
        base,
        'client',
        clientId,
        'reports',
        reportId,
        'versions',
        versionId,
        'files-ready-for-processing',
      ),
    listVersionFilesMetadata: (clientId: string, reportId: string, versionId: string) =>
      queryKeyBuilder(base, 'client', clientId, 'reports', reportId, 'versions', versionId, 'listVersionFilesMetadata'),
    deleteReport: (clientId: string) => queryKeyBuilder(base, 'client', clientId, 'deleteReport'),
  };
})();

export const useGetReport = (
  clientId: string,
  reportId: string,
  options?: Omit<UseQueryOptions<ReportScopeRepresentation>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<ReportScopeRepresentation>({
    queryFn: async function getReport() {
      const response = await dazzler.reports.getReportScope({
        clientId,
        reportId,
      });

      return response.data;
    },
    queryKey: reportKeys.report(clientId, reportId),
    enabled: !!clientId && !!reportId,
    ...options,
  });

interface DeleteReportScopeVariables {
  reportId: string;
}

export const useDeleteReport = (
  clientId: string,
  options?: Omit<UseMutationOptions<void, unknown, DeleteReportScopeVariables, unknown>, 'mutationFn' | 'mutationKey'>,
) =>
  useMutation({
    mutationFn: ({ reportId }: DeleteReportScopeVariables) =>
      dazzler.reports.deleteReportScope({ clientId, reportId }).then((res) => res.data),
    mutationKey: reportKeys.deleteReport(clientId),
    ...options,
  });

export type ListReportsParams = SortingParams<ReportScopeSort> &
  PagingParams & {
    search?: string;
    scope?: ScopeType;
  };

export const useGetReports = (
  clientId: string,
  params?: ListReportsParams,
  options?: Omit<UseQueryOptions<ListRepresentation<ReportScopeRepresentation>>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<ListRepresentation<ReportScopeRepresentation>>({
    queryFn: async function getReports() {
      const response = await dazzler.reports.listReportScopes({
        clientId,
        ...params,
      });

      return response.data;
    },
    queryKey: reportKeys.reports(clientId, params),
    enabled: !!clientId,
    ...options,
  });

export const useGetReportingFrameworks = (
  clientId: string,
  reportId: string,
  options?: Omit<UseQueryOptions<Frameworks>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryFn: async function getReportingFrameworks() {
      const response = await dazzler.reports.getReportingFrameworks({
        clientId,
        reportId,
      });

      return response.data;
    },
    queryKey: reportKeys.reportingFrameworks(clientId, reportId),
    enabled: !!clientId && !!reportId,
    staleTime: minutesToMilliseconds(5),
    ...options,
  });

type CreateReportScopeVariables = {
  clientId: string;
  body: ReportScopeValidator;
};

export const useCreateReportScope = (
  options?: Omit<
    UseMutationOptions<ReportScopeRepresentation, unknown, CreateReportScopeVariables, unknown>,
    'mutationFn' | 'mutationKey'
  >,
) =>
  useMutation({
    mutationFn: async function createReportScope({ clientId, body }: CreateReportScopeVariables) {
      const response = await dazzler.reports.createReportScope({ clientId, reportScopeValidator: body });

      return response.data;
    },
    mutationKey: reportKeys.create(),
    ...options,
  });

export const useUploadReportFile = (
  options?: Omit<
    UseMutationOptions<
      ReportFileRepresentation,
      AxiosError,
      {
        clientId: string;
        reportId: string;
        file: File;
        reportingFramework: string;
        onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
        signal?: AbortSignal;
      },
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) => {
  return useMutation({
    mutationKey: reportKeys.uploadFile(),
    mutationFn: async function uploadReportFile({
      clientId,
      reportId,
      file,
      reportingFramework,
      onUploadProgress,
      signal,
    }) {
      const response = await dazzler.reports.uploadReportFile(
        {
          clientId,
          reportId,
          file,
          framework: reportingFramework,
        },
        {
          onUploadProgress,
          signal,
        },
      );

      return response.data;
    },
    ...options,
  });
};

interface DeleteFileVariables {
  fileId: string;
}

export const useDeleteReportFile = (
  clientId: string,
  reportId: string,
  options?: Omit<UseMutationOptions<void, unknown, DeleteFileVariables, unknown>, 'mutationFn' | 'mutationKey'>,
) =>
  useMutation({
    mutationFn: async function deleteReportFile({ fileId }: DeleteFileVariables) {
      const response = await dazzler.reports.deleteReportFile({ clientId, reportId, fileId });

      return response.data;
    },
    mutationKey: reportKeys.delete(clientId, reportId),
    ...options,
  });

export const useStartReportWorkflow = (
  clientId: string,
  reportId: string,
  options?: Omit<UseMutationOptions<void, unknown, {}, unknown>, 'mutationFn' | 'mutationKey'>,
) =>
  useMutation({
    mutationFn: async function startReportWorkflow() {
      const response = await dazzler.reports.startReportWorkflow({ clientId, reportId });

      return response.data;
    },
    mutationKey: reportKeys.startWorkflow(clientId, reportId),
    ...options,
  });

export type ReportLogsParams = SortingParams<AuditLogSort> &
  PagingParams & {
    search?: string;
  };

export const useReportLogs = (
  clientId: string,
  reportId: string,
  params?: ReportLogsParams,
  options?: Omit<UseQueryOptions<ListRepresentation<AuditLogRepresentation>>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryFn: async function getReportLogs() {
      const response = await dazzler.reports.listReportLogs({
        clientId,
        reportId,
        ...params,
      });

      return response.data;
    },
    queryKey: reportKeys.logs(clientId, reportId, params),
    ...options,
  });

export const useGetVersions = (
  clientId: string,
  reportId: string,
  version: string,
  options?: Omit<UseQueryOptions<VersionRepresentation>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryFn: async function getVersion() {
      const response = await dazzler.reports.getVersion({
        clientId,
        reportId,
        version,
      });

      return response.data;
    },
    queryKey: reportKeys.version(clientId, reportId, version),
    ...options,
  });

export type ListVersionsParams = PagingParams & SortingParams<VersionSort>;

export function useReportVersionsList(
  clientId: string,
  reportId: string,
  params: ListVersionsParams = {},
  options?: Omit<UseQueryOptions<ListRepresentation<VersionRepresentation>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryFn: async function listVersions() {
      const response = await dazzler.reports.listVersions({
        clientId,
        reportId,
        ...params,
      });

      return response.data;
    },
    queryKey: reportKeys.versionList(clientId, reportId, params),
    ...options,
  });
}

export type ListReportFilesParams = PagingParams;

export function useReportFilesList(
  clientId: string,
  reportId: string,
  params: ListReportFilesParams,
  options?: Omit<UseQueryOptions<ListRepresentation<ReportFileRepresentation>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryFn: async function listReportFiles() {
      const response = await dazzler.reports.listReportFiles({
        clientId,
        reportId,
        ...params,
      });

      return response.data;
    },
    queryKey: reportKeys.fileList(clientId, reportId, params),
    ...options,
  });
}

export type ValidationChecksParams = SortingParams<ReviewedValidationCheckSort> &
  PagingParams & {
    status?: ValidationCheckReviewStatus;
    subject?: string;
    source?: string;
    threshold?: string;
    code?: string;
    expression?: string;
    substitution?: string;
    search?: string;
  };

export const useGetValidationChecks = (
  clientId: string,
  reportId: string,
  versionId: string,
  params: ValidationChecksParams,
  options?: Omit<
    UseInfiniteQueryOptions<FilteredListRepresentation<ReviewedValidationCheckRepresentation>>,
    'queryKey' | 'queryFn'
  >,
) =>
  useInfiniteQuery({
    queryKey: reportKeys.validationChecks(clientId, reportId, versionId, params),
    queryFn: async function getValidationChecks({ pageParam = { limit: 10, offset: 0, ...params } }) {
      const response = await dazzler.reports.listChecks({
        clientId,
        reportId,
        versionId,
        ...pageParam,
      });

      return response.data;
    },
    getNextPageParam: (lastPage, allPages) => {
      const nextOffset = allPages.reduce((prev, next) => prev + next.items.length, 0);
      if (lastPage.count - nextOffset > 0) {
        return { limit: 10, offset: nextOffset, ...params };
      }
      return undefined;
    },
    enabled: !!clientId && !!reportId && !!versionId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...options,
  });

export const useGetReportingEngineProgress = (
  clientId: string,
  reportId: string,
  options?: Omit<UseQueryOptions<ReportingEngineProgressRepresentation>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryFn: async function getReportingEngineProgress() {
      const response = await dazzler.reports.getReportingEngineProgress({ clientId, reportId });

      return response.data;
    },
    queryKey: reportKeys.reportingEngineProgress(clientId, reportId),
    enabled: !!clientId && !!reportId,
    ...options,
  });

export const useWaiveChecks = (
  clientId: string,
  reportId: string,
  versionId: string,
  options?: Omit<
    UseMutationOptions<void, unknown, WaiveValidationChecksValidator, unknown>,
    'mutationFn' | 'mutationKey'
  >,
) =>
  useMutation({
    mutationFn: async function waiveChecks(body: WaiveValidationChecksValidator) {
      const response = await dazzler.reports.waiveChecks({
        clientId,
        reportId,
        versionId,
        waiveValidationChecksValidator: body,
      });

      return response.data;
    },
    mutationKey: reportKeys.markChecks(clientId, reportId, versionId, 'waive'),
    ...options,
  });

export const useFlagChecks = (
  clientId: string,
  reportId: string,
  versionId: string,
  options?: Omit<
    UseMutationOptions<void, unknown, FlagValidationChecksValidator, unknown>,
    'mutationFn' | 'mutationKey'
  >,
) =>
  useMutation({
    mutationFn: async function flagChecks(body: FlagValidationChecksValidator) {
      const response = await dazzler.reports.flagChecks({
        clientId,
        reportId,
        versionId,
        flagValidationChecksValidator: body,
      });

      return response.data;
    },
    mutationKey: reportKeys.markChecks(clientId, reportId, versionId, 'flag'),
    ...options,
  });

export const useMarkChecksPending = (
  clientId: string,
  reportId: string,
  versionId: string,
  options?: Omit<
    UseMutationOptions<void, unknown, MarkValidationChecksPendingValidator, unknown>,
    'mutationFn' | 'mutationKey'
  >,
) =>
  useMutation({
    mutationFn: async function markChecksPending(body: MarkValidationChecksPendingValidator) {
      const response = await dazzler.reports.markChecksPending({
        clientId,
        reportId,
        versionId,
        markValidationChecksPendingValidator: body,
      });

      return response.data;
    },
    mutationKey: reportKeys.markChecks(clientId, reportId, versionId, 'pending'),
    ...options,
  });

export const useMarkChecksBelowThreshold = (
  clientId: string,
  reportId: string,
  versionId: string,
  options?: Omit<
    UseMutationOptions<void, unknown, MarkValidationChecksBelowThresholdValidator, unknown>,
    'mutationFn' | 'mutationKey'
  >,
) =>
  useMutation({
    mutationFn: async function markChecksBelowThreshold(body: MarkValidationChecksBelowThresholdValidator) {
      const response = await dazzler.reports.markChecksBelowThreshold({
        clientId,
        reportId,
        versionId,
        markValidationChecksBelowThresholdValidator: body,
      });

      return response.data;
    },
    mutationKey: reportKeys.markChecks(clientId, reportId, versionId, 'belowThreshold'),
    ...options,
  });

export function useListVersionVisualExcels(
  clientId: string,
  reportId: string,
  versionId: string,
  options?: UseQueryOptions<EndpointResponse<ReportsApi['listVersionVisualExcels']>>,
) {
  return useQuery({
    queryKey: reportKeys.listVersionVisualExcel(clientId, reportId, versionId),
    queryFn: async function listVersionVisualExcels() {
      const response = await dazzler.reports.listVersionVisualExcels({ clientId, reportId, versionId });

      return response.data;
    },
    ...options,
  });
}

export function useListReportDownloadOptions(
  clientId: string,
  reportId: string,
  options?: UseQueryOptions<DownloadRepresentation[]>,
) {
  return useQuery({
    queryKey: reportKeys.listDownloads(clientId, reportId),
    queryFn: async function listReportDownloadOptions() {
      const response = await dazzler.reports.listDownloads({
        clientId,
        reportId,
      });

      return response.data;
    },
    ...options,
  });
}

export function useDownloadReportAssets(
  options?: UseMutationOptions<
    void,
    Error,
    {
      uri: string;
      signal?: AbortSignal;
      onDownloadProgress?: KyOptions['onDownloadProgress'];
    }
  >,
) {
  return useMutation({
    mutationFn: async function downloadReportAssets({ uri, signal, onDownloadProgress }) {
      const endpoint = new URL(uri, baseURL);
      const res = await ky(endpoint, {
        headers: {
          Authorization: api.getAuthorizationHeader() ?? '',
        },
        retry: {
          limit: 3,
        },
        signal,
        onDownloadProgress,
      });

      const fallback = endpoint.pathname.split('/').at(-1);

      const blob = await res.blob();
      const fileName = getFilename(res.headers.get('Content-Disposition'), fallback ?? 'report-asset');

      const file = new File([blob], fileName, { type: 'application/octet-stream' });
      downloadFile(file);
    },
    ...options,
  });
}

export const useLockReport = (
  clientId: string,
  reportId: string,
  options?: Omit<UseMutationOptions<void, unknown, {}, unknown>, 'mutationFn' | 'mutationKey'>,
) =>
  useMutation({
    mutationFn: async function lockReport() {
      const response = await dazzler.reports.lockReport({ clientId, reportId });

      return response.data;
    },
    mutationKey: reportKeys.lockReport(clientId, reportId),
    ...options,
  });

export const useApplyActionToAllChecks = (
  clientId: string,
  reportId: string,
  versionId: string,
  options?: Omit<
    UseMutationOptions<void, unknown, { body: ApplyActionToAllValidationChecksValidator }, unknown>,
    'mutationFn' | 'mutationKey'
  >,
) =>
  useMutation({
    mutationFn: async function applyActionToAllChecks({ body }) {
      const response = await dazzler.reports.applyActionToAllChecks({
        clientId,
        reportId,
        versionId,
        applyActionToAllValidationChecksValidator: body,
      });

      return response.data;
    },
    mutationKey: reportKeys.applyActionToAllChecks(clientId, reportId, versionId),
    ...options,
  });

export function useListReportFileUploadProgresses(
  clientId: string,
  reportId: string,
  options?: UseQueryOptions<EndpointResponse<ReportsApi['listReportFilesUploadProgresses']>>,
) {
  return useQuery({
    queryFn: async function listReportFilesUploadProgresses({ signal }) {
      const response = await dazzler.reports.listReportFilesUploadProgresses({ clientId, reportId }, { signal });

      return response.data;
    },
    queryKey: reportKeys.listReportFileUploadProgresses(clientId, reportId),
    ...options,
  });
}

export function useListVersionFilesReadyForProcessing(
  clientId: string,
  reportId: string,
  versionId: string,
  options?: UseQueryOptions<EndpointResponse<ReportsApi['listVersionFilesReadyForProcessing']>>,
) {
  return useQuery({
    queryFn: async function listVersionFilesReadyForProcessing({ signal }) {
      const response = await dazzler.reports.listVersionFilesReadyForProcessing(
        {
          clientId,
          reportId,
          versionId,
        },
        { signal },
      );

      return response.data;
    },
    queryKey: reportKeys.listVersionFilesReadyForProcessing(clientId, reportId, versionId),
    ...options,
  });
}

export function useGetListVersionFilesMetadata(
  clientId: string,
  reportId: string,
  versionId: string,
  options?: UseQueryOptions<EndpointResponse<ReportsApi['listVersionFilesMetadata']>>,
) {
  return useQuery({
    queryFn: async function listVersionFilesMetadata() {
      const response = await dazzler.reports.listVersionFilesMetadata({
        clientId,
        reportId,
        versionId,
      });

      return response.data;
    },
    queryKey: reportKeys.listVersionFilesMetadata(clientId, reportId, versionId),
    enabled: !!clientId && !!reportId && !!versionId,
    ...options,
  });
}
