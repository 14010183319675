import { formatDate } from '~/app/utils/format-data';
import { Text } from '~/ui/index.js';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import s from './styles.module.scss';
import { useGetListVersionFilesMetadata, useGetVersions, useListVersionVisualExcels } from '~/data/reports/queries';
import { useParams } from 'react-router-dom';
import { useGetClient } from '~/data/clients/queries';
import { CountryCode } from '~/data/openapi-client';
import { downloadFileByURL } from '~/data/downloads/file';
import CloseIcon from '@mui/icons-material/Close';

interface UploadedFilesProps {
  versionId: string;
  open: boolean;
  onClose: () => void;
}

const UploadedFilesDialog = ({ versionId, open, onClose }: UploadedFilesProps) => {
  const { clientId, reportId } = useParams<{ clientId: string; reportId: string }>();
  const { data: client } = useGetClient(clientId);
  const { data: version } = useGetVersions(clientId, reportId, versionId);
  const { data: versionFilesMetadata } = useGetListVersionFilesMetadata(clientId, reportId, versionId);

  const versionFilesMetadataMap = new Map(versionFilesMetadata?.map((item) => [item.id, item]));

  const { files } = version ?? {};

  const { data: visualExcels, isInitialLoading: isVisualExcelsLoading } = useListVersionVisualExcels(
    clientId,
    reportId,
    versionId,
    {
      enabled: !!clientId && !!reportId && !!versionId,
    },
  );

  const onDownloadExcel = (fileId: string) => {
    const visualExcel = visualExcels?.find((item) => item.versionFileId === fileId);
    if (!visualExcel) return;
    downloadFileByURL(visualExcel.downloadURI, visualExcel.filename);
  };

  const visualExcelsEmpty = !isVisualExcelsLoading && !visualExcels?.length;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle sx={{ m: 0, p: 0 }} className="flex justify-between items-center">
        <Typography fontSize={20}>Uploaded files</Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="50%" key="periodEnd">
                <Text weight={Text.weight.semiMedium} size={Text.size.s}>
                  File
                </Text>
              </TableCell>
              <TableCell width="25%" key="type">
                <Text weight={Text.weight.semiMedium} size={Text.size.s}>
                  Date
                </Text>
              </TableCell>
              {client?.countryCode === CountryCode.Be && !visualExcelsEmpty && (
                <>
                  <TableCell width="25%" key="visualExcel">
                    <Text weight={Text.weight.semiMedium} size={Text.size.s}>
                      Visual Excel
                    </Text>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          {!!files?.length && (
            <TableBody>
              {files.map((file) => (
                <TableRow>
                  <TableCell width="50%" className={s.filesTableFileCol}>
                    <Text size={Text.size.s}>{file.name}</Text>
                    <Text size={Text.size.xs} color={Text.color.light}>
                      {(() => {
                        const metadata = versionFilesMetadataMap.get(file.id);
                        return (
                          <>
                            {metadata?.containerVersion ? (
                              <>
                                container version: {metadata.containerVersion} <br />
                              </>
                            ) : null}
                            {metadata?.validationVersion ? (
                              <>
                                container version: {metadata.validationVersion} <br />
                              </>
                            ) : null}
                            {versionFilesMetadataMap
                              .get(file.id)
                              ?.taxonomies.map((item) => item.name)
                              .join(', ')}
                          </>
                        );
                      })()}
                    </Text>
                  </TableCell>
                  <TableCell width="25%">
                    <Text size={Text.size.s}>{file.inserted && formatDate(file.inserted)}</Text>
                  </TableCell>
                  {client?.countryCode === CountryCode.Be && !visualExcelsEmpty && (
                    <>
                      <TableCell width="25%">
                        {isVisualExcelsLoading ? (
                          <Skeleton variant="text" />
                        ) : (
                          <Button
                            onClick={() => onDownloadExcel(file.id)}
                            size="small"
                            variant="text"
                            className={s.filesTableTextButton}
                            startIcon={<FileDownloadOutlinedIcon />}
                          >
                            <Text size={Text.size.s}>download</Text>
                          </Button>
                        )}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Dialog>
  );
};

export default UploadedFilesDialog;
