import React, { useEffect } from 'react';

function AnchorInternal({ link, path, component, register, unregister, update, wrapperStyle = null }) {
  useEffect(() => {
    register({
      link,
      path,
      component,
      wrapperStyle,
    });
    return () => {
      unregister({
        link,
        path,
        component,
        wrapperStyle,
      });
    };
  }, []);

  useEffect(() => {
    if (component) {
      update({
        link,
        path,
        component,
        wrapperStyle,
      });
    }
  }, [component]);

  return null;
}

const BreadcrumbsAnchor = ({ context, ...props }) => {
  const { Consumer } = context;
  return (
    <Consumer>
      {(value) => (
        <AnchorInternal register={value.register} unregister={value.unregister} update={value.update} {...props} />
      )}
    </Consumer>
  );
};

// BreadcrumbsAnchor.propTypes = {
//   context: PropTypes.shape({
//     Provider: PropTypes.object.isRequired,
//     Consumer: PropTypes.object.isRequired,
//   }).isRequired,
// };

export default BreadcrumbsAnchor;
