import type { ComparisonRow } from '~/data/openapi-client/index';
import { connection, Database, duckDB, executeCompiledQuery } from '~/db';
import type * as duckdb from '@duckdb/duckdb-wasm';
import type { CompiledQuery, Kysely, ReferenceExpression } from 'kysely';

export type TableName = `comparison_rows_${string}`;

export type ComparisonRowsTable = ComparisonRow;

export function createTableName(comparisonId: string): TableName {
  comparisonId = comparisonId.replaceAll('-', '_');
  return `comparison_rows_${comparisonId}`;
}

export function createFilename(comparisonId: string): string {
  comparisonId = comparisonId.replaceAll('-', '_');

  return `comparison_rows_${comparisonId}.parquet`;
}

export async function registerComparisonFile(buffer: Uint8Array, comparisonId: string): Promise<TableName> {
  const filename = createFilename(comparisonId);
  await duckDB.registerFileBuffer(filename, buffer);
  const tableName = createTableName(comparisonId);

  await connection.query(`create table if not exists ${tableName} as select * from read_parquet(${filename})`);
  await connection.query(`alter table ${tableName} add if not exists RelativeDifference double`);
  await connection.query(`alter table ${tableName} add if not exists RelativeDifferencePercent double`);
  await connection.query(`update ${tableName} set RelativeDifference = LeftValueParsed - RightValueParsed`);
  await connection.query(
    `update ${tableName} set RelativeDifferencePercent = ((RelativeDifference / LeftValueParsed) * 100)`,
  );

  return tableName;
}

const operatorMapper = {
  contains: 'ilike',
  startsWith: 'ilike',
  endsWith: 'ilike',
  doesNotContain: 'not like',
  equals: '=',
  doesNotEqual: '!=',
  isAnyOf: 'in',
  '=': '=',
  '!=': '!=',
  '>': '>',
  '>=': '>=',
  '<': '<',
  '<=': '<=',
};

const processValue = (str: string, operator: string) => {
  if (operator === 'contains') return `%${str}%`;
  if (operator === 'doesNotContain') return `%${str}%`;
  if (operator === 'startsWith') return `${str}%`;
  if (operator === 'endsWith') return `%${str}`;
  if (operator === 'isAnyOf' && str.length) return str.split(',');
  return str;
};

export async function listComparisons(
  conn: duckdb.AsyncDuckDBConnection,
  query: CompiledQuery,
): Promise<ComparisonRow[]> {
  const result = await executeCompiledQuery(query, conn);

  return result.toArray().map((x) => x.toJSON());
}

export async function countComparisons(conn: duckdb.AsyncDuckDBConnection, query: CompiledQuery): Promise<number> {
  const result = await executeCompiledQuery(query, conn);

  return result.toArray()[0].count;
}

export type Column = keyof ComparisonRow;
export type Direction = 'asc' | 'desc';

export type Filters = {
  sort: Column;
  direction: Direction;
  limit: number;
  offset: number;
  tableCode?: string;
  columnCode?: string;
  rowCode?: string;
  search?: string;
  filterColumn: string;
  filterOperator: string;
  filterValue: string;
  numericValue?: boolean;
};

export function createQuery(db: Kysely<Database>, comparisonId: string, filters: Filters) {
  const operator = operatorMapper[filters.filterOperator ?? ''];

  let value: string | string[] | number | number[];
  if (filters.filterValue === '') {
    value = '';
  } else if (filters.filterOperator !== 'isAnyOf') {
    value = filters.numericValue ? +filters.filterValue : processValue(filters.filterValue, filters.filterOperator);
  } else {
    const splitValue = filters.filterValue.split(',');
    value = filters.numericValue ? splitValue.map((val) => +val) : splitValue;
  }

  let qb = db
    .selectFrom(`comparison_rows_${comparisonId.replaceAll('-', '_')}`)
    .$if(!!filters.tableCode, (qb) => qb.where('TableCode', '=', filters.tableCode!))
    .$if(!!filters.columnCode, (qb) => qb.where('ColumnCode', '=', filters.columnCode!))
    .$if(!!filters.rowCode, (qb) => qb.where('RowCode', '=', filters.rowCode!))
    .$if(!!filters.search, (qb) => {
      return qb.where('TableCode', 'ilike', `%${filters.search}%`);
    })
    .$if(filters.filterOperator === 'isEmpty', (qb) =>
      qb.where((eb) =>
        eb.or([
          eb(
            filters.filterColumn as ReferenceExpression<Database, `comparison_rows_${string}`>,
            '=',
            filters.numericValue ? +value : value,
          ),
          eb(filters.filterColumn as ReferenceExpression<Database, `comparison_rows_${string}`>, 'is', null),
        ]),
      ),
    )
    .$if(filters.filterOperator === 'isNotEmpty', (qb) =>
      qb.where((eb) =>
        eb.and([
          eb(
            filters.filterColumn as ReferenceExpression<Database, `comparison_rows_${string}`>,
            '!=',
            filters.numericValue ? +value : value,
          ),
          eb(filters.filterColumn as ReferenceExpression<Database, `comparison_rows_${string}`>, 'is not', null),
        ]),
      ),
    )
    .$if(!!filters.filterColumn && !!operator && value != null && value !== '', (qb) =>
      qb.where(filters.filterColumn as ReferenceExpression<Database, `comparison_rows_${string}`>, operator, value),
    );

  const selectQuery = qb
    .selectAll()
    .offset(filters.offset)
    .limit(filters.limit)
    .orderBy(filters.sort, filters.direction);

  const countQuery = qb.select((eb) => eb.fn.countAll().as('count'));

  return {
    select: selectQuery.compile(),
    count: countQuery.compile(),
  };
}
