/* eslint-disable react/default-props-match-prop-types */
import FormControl from '~/app/components/core/form/control/index';
import React from 'react';

type anyFunc = (...args: any) => any;
type formFieldProps = {
  field: {
    name: string;
    value: any;
    onChange: anyFunc;
    onBlur: anyFunc;
  };
  form: {
    setFieldValue: anyFunc;
    setFieldError: anyFunc;
    setFieldTouched: anyFunc;
    touched: any;
    errors: any;
  };
  onBlurAdapter: anyFunc;
  valueAdapter: anyFunc;
  onChangeAdapter: anyFunc;
};

class FormField extends React.Component<formFieldProps> {
  static defaultProps = {
    onBlurAdapter: null,
    onChangeAdapter: null,
    valueAdapter: (value) => value,
  };

  onChange = (...args) => {
    const { form, field, onChangeAdapter } = this.props;
    form.setFieldValue(field.name, onChangeAdapter(...args));
  };

  onBlur = (...args) => {
    const { form, field, onBlurAdapter } = this.props;
    form.setFieldTouched(field.name, onBlurAdapter(...args));
  };

  extractFieldProps = () => {
    const {
      field,
      form: { errors, setFieldValue, setFieldError, touched },
      onChangeAdapter,
      onBlurAdapter,
      valueAdapter,
      ...props
    } = this.props;

    let error = null;
    let touchedField = null;
    if (field.name.includes('.')) {
      const keys = field.name.split('.');
      error = keys.reduce((previous, current) => previous && previous[current], errors);
      touchedField = keys.reduce((previous, current) => previous && previous[current], touched);
    } else {
      error = errors[field.name];
      touchedField = touched[field.name];
    }

    const fieldProps = {
      ...field,
      ...props,
      error,
      touched: touchedField,
      value: valueAdapter(field.value),
      setFieldValue,
      setFieldError,
    };

    if (onChangeAdapter) {
      fieldProps.onChange = this.onChange;
    }

    if (onBlurAdapter) {
      fieldProps.onBlur = this.onBlur;
    }

    return fieldProps;
  };

  render() {
    return <FormControl {...this.extractFieldProps()} />;
  }
}

export default FormField;
