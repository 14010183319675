import { Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import { Show } from '~/app/components/core/show';
import Row from '~/ui/components/grid/row';
import { useHistory, useParams } from 'react-router-dom';
import cx from '~/data/utils/helpers/cx';
import { clientComparisonsPath, comparisonDetails } from '~/app/constants/url/auditor';
import { useQueryClient } from '@tanstack/react-query';
import {
  comparisonKeys,
  useCreateComparison,
  useListComparisonFiles,
  useUploadComparisonFile,
} from '~/data/comparisons/queries';

const CompareFiles = () => {
  const { clientId } = useParams<{ clientId?: string }>();
  const history = useHistory();
  const [file1, setFile1] = useState<string | undefined>(undefined);
  const [file2, setFile2] = useState<string | undefined>(undefined);
  const [uploadedFile, setUploadedFile] = useState<{ name: string; error?: boolean } | undefined>(undefined);

  const queryClient = useQueryClient();

  const { data: fileList } = useListComparisonFiles(clientId ?? '');
  const {
    mutate: uploadFile,
    reset: resetFileUpload,
    isLoading: isFileUploading,
  } = useUploadComparisonFile(clientId ?? '', {
    useErrorBoundary: false,
    onSuccess: (data) => {
      setFile2(data.id);
    },
    onError: () => {
      setUploadedFile((prev) => ({ ...prev, error: true }));
    },
  });
  const { mutate: createComparison } = useCreateComparison(clientId ?? '');
  const fileOptions = useMemo(
    () => [
      { value: undefined, label: 'None' },
      ...(fileList?.items.map((item, index) => ({ value: item.id, label: item.name })) ?? []),
    ],
    [fileList],
  );

  return (
    <div>
      <div>
        <Row className="justify-between">
          <Typography fontSize={24} component="h1" className="text-dark">
            Compare files
          </Typography>
        </Row>
        <Typography fontSize={14} className="text-light">
          Comparison between 2 versions of the same type of report
        </Typography>
      </div>
      <div className="flex w-full mt-4 gap-4 items-center">
        <div className="basis-1/2">
          <Typography fontSize={14} className="text-light">
            File 1
          </Typography>
          <div className="p-5 bg-white rounded-md">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Choose file</InputLabel>
              <Select
                className="w-full"
                value={file1}
                onChange={(e) => {
                  setFile1(e.target.value);
                }}
                MenuProps={{
                  classes: { paper: 'max-w-[650px]' },
                  MenuListProps: { classes: { root: 'overflow-auto' } },
                }}
                label="Choose file"
              >
                {fileOptions?.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="basis-1/2">
          <Typography fontSize={14} className="text-light">
            File 2
          </Typography>
          <div className="p-5 bg-white rounded-md flex items-center gap-2">
            <Show when={!uploadedFile}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Choose file</InputLabel>
                <Select
                  className="w-full"
                  value={file2}
                  onChange={(e) => {
                    setFile2(e.target.value);
                  }}
                  placeholder="Choose file"
                  label="Choose file"
                  MenuProps={{
                    classes: { paper: 'max-w-[650px]' },
                    MenuListProps: { classes: { root: 'overflow-auto' } },
                  }}
                >
                  {fileOptions?.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Show>
            <Show when={!file2 && !uploadedFile}>
              <Typography>Or</Typography>
            </Show>
            <Dropzone
              accept={{ 'application/xbrl': ['.xbrl'] }}
              onDrop={(acceptedFiles) => {
                const file = acceptedFiles[0];
                setUploadedFile({
                  name: file.name,
                });
                uploadFile({ file });
              }}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <Show
                  when={!uploadedFile}
                  fallback={
                    <div {...getRootProps()} className="grow">
                      <Show when={!uploadedFile}>
                        <input {...getInputProps()} />
                      </Show>
                      <ul className="flex flex-col gap-2 w-full">
                        <li
                          key={uploadedFile?.name}
                          className={cx(
                            'bg-secondary-main justify-between items-center px-4 py-2 rounded-md grid grid-cols-[1fr_auto] gap-2',
                            !!uploadedFile?.error && 'border-red border',
                            isFileUploading && 'grid-cols-[auto_1fr_auto]',
                          )}
                        >
                          <Show when={isFileUploading}>
                            <CircularProgress thickness={6} size={16} />
                          </Show>

                          <span className={cx('truncate', !!uploadedFile?.error ? 'text-red' : '')}>
                            {uploadedFile?.name}
                          </span>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (isFileUploading) {
                                resetFileUpload();
                              }
                              setUploadedFile(undefined);
                              setFile2(undefined);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        </li>
                      </ul>
                    </div>
                  }
                >
                  <div {...getRootProps()}>
                    <Show when={!uploadedFile}>
                      <input {...getInputProps()} />
                    </Show>
                    <Show when={!file2 && !uploadedFile}>
                      <Typography>
                        <span role="button" className="text-orange underline">
                          Upload
                        </span>
                      </Typography>
                    </Show>
                  </div>
                </Show>
              )}
            </Dropzone>
          </div>
        </div>
        <Button
          size="large"
          variant="contained"
          disabled={!file1 || !file2}
          className="mt-[21px]"
          onClick={() => {
            createComparison(
              {
                body: {
                  leftComparisonFileId: file1,
                  rightComparisonFileId: file2,
                },
              },
              {
                onSuccess: (data) => {
                  queryClient.invalidateQueries({
                    queryKey: comparisonKeys.clientComparisonList(clientId ?? ''),
                    exact: false,
                    type: 'all',
                  });
                  if (data.id) {
                    history.push(comparisonDetails(clientId, data.id));
                  } else {
                    history.push(clientComparisonsPath(clientId));
                  }
                },
              },
            );
          }}
        >
          Compare
        </Button>
      </div>
    </div>
  );
};

export default CompareFiles;
