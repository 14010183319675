import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  InputBase,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import * as Tabs from '@radix-ui/react-tabs';
import {
  ReportLifecycleStatus,
  ReviewedValidationCheckRepresentation,
  ValidationCheckReviewStatus,
  ValidationChecksAction,
  VersionRepresentation,
  VersionStatus,
} from '~/data/openapi-client/index';
import cx from '~/data/utils/helpers/cx';
import {
  reportKeys,
  useApplyActionToAllChecks,
  useFlagChecks,
  useGetReport,
  useGetValidationChecks,
  useGetVersions,
  useListVersionVisualExcels,
  useMarkChecksBelowThreshold,
  useMarkChecksPending,
  useWaiveChecks,
} from '~/data/reports/queries';
import { Redirect, useParams } from 'react-router-dom';
import { useDebounceValue } from '~/app/utils/debounce';
import useIntersectionObserver from '~/app/utils/hooks/useIntersectionObserver';
import { Show } from '~/app/components/core/show';
import { CheckCard } from './card';
import { InfiniteData, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import CloseIcon from '@mui/icons-material/Close';
import { CheckSource, FilteredListRepresentation } from '~/data/utils/types';
import sortBy from 'lodash/sortBy';
import { Workbook, type WorkbookHandle } from '~/app/components/visual-excel/workbook';
import { StringParam, useQueryParams, withDefault, createEnumParam } from 'use-query-params';
import { ValidationCheckReviewStatusParam } from '~/data/query-params';
import type { CellRange } from '~/app/components/visual-excel/worksheet';
import { logger } from '~/logger';
import { Match, Switch } from '~/app/components/core/switch';
import { reportVersionReadonlyCheckPath } from '~/app/constants/url/auditor';

const sourceOptions = [
  {
    value: CheckSource.PWC,
    label: CheckSource.PWC,
  },
  {
    value: CheckSource.EBA,
    label: CheckSource.EBA,
  },
  {
    value: undefined,
    label: 'All',
  },
];

const pluralRuleToChecks = new Map([
  ['one', 'check'],
  ['other', 'checks'],
]);

const thresholdSelectOptions = [
  {
    label: '<',
    value: 'lt',
  },
  {
    label: '>',
    value: 'gt',
  },
] as const;

type ThresholdFilter = (typeof thresholdSelectOptions)[number]['value'];

const enCardinalRules = new Intl.PluralRules('en-US');

function LatestChecksPage() {
  const { clientId = '', reportId = '' } = useParams<{ clientId?: string; reportId?: string }>();
  const lastCardRef = React.useRef<HTMLLIElement | null>(null);
  const workbookHandle = React.useRef<WorkbookHandle>(null);
  const [params, setParams] = useQueryParams({
    status: withDefault(ValidationCheckReviewStatusParam, ValidationCheckReviewStatus.Pending),
    search: withDefault(StringParam, undefined),
    source: withDefault(StringParam, undefined),
    threshold: withDefault(StringParam, undefined),
    thresholdFilter: withDefault(createEnumParam(thresholdSelectOptions.map((item) => item.value)), 'gt'),
    subject: withDefault(StringParam, undefined),
  });
  const [reportFileId, setReportFileId] = useState<string | null>(null);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [motivation, setMotivation] = useState<string | undefined>(undefined);
  const [motivationError, setMotivationError] = useState(false);
  const [openMotivationDialog, setOpenMotivationDialog] = useState(false);
  const [status, setStatus] = useState<null | 'waived' | 'flagged' | 'belowThreshold'>(null);
  const [openedCheckId, setOpenedCheckId] = useState<null | string>(null);

  const search = useDebounceValue(params.search, 300);
  const { source, threshold, subject, thresholdFilter } = params;

  const { data: report, isInitialLoading: isReportLoading } = useGetReport(clientId, reportId);
  const { data: latestVersion } = useGetVersions(clientId, reportId, 'latest');

  const reportVersions: VersionRepresentation[] = sortBy(report?.versions, (v) => v.order)?.reverse();
  const latestProcessedVersionId =
    latestVersion?.status === VersionStatus.Draft
      ? reportVersions.find((v) => v.validationChecksCount > 0)?.id
      : latestVersion?.id;

  const { data: latestProcessedVersion, isInitialLoading: isLatestProcessedVersionLoading } = useGetVersions(
    clientId,
    reportId,
    latestProcessedVersionId ?? '',
  );
  const { data: visualExcels, isLoading: isVisualExcelListLoading } = useListVersionVisualExcels(
    clientId,
    reportId,
    latestProcessedVersionId ?? '',
    {
      enabled: !!clientId && !!reportId && !!latestProcessedVersion?.id,
    },
  );

  // versionParticlesFileId, reportFileId
  const versionFileMap = useMemo(() => {
    const map = new Map<string, string>();

    for (const visualExcel of visualExcels ?? []) {
      map.set(visualExcel.versionParticlesFileId, visualExcel.reportFileId);
    }

    return map;
  }, [visualExcels]);

  const subjectOptions = [
    ...(latestProcessedVersion?.subjects?.map((item) => ({
      value: item,
      label: item,
    })) ?? []),
    {
      value: undefined,
      label: 'All',
    },
  ];

  const {
    data: checks,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isChecksLoading,
    isFetching: isChecksFetching,
  } = useGetValidationChecks(clientId, reportId, latestProcessedVersionId ?? '', {
    ...params,
    status: params.status,
    search,
    threshold:
      params.threshold && !Number.isNaN(+params.threshold)
        ? `${params.thresholdFilter} ${params.threshold}`
        : undefined,
  });

  const latestFilterHash = checks?.pages.at(-1)?.filterHash ?? '';

  const queryClient = useQueryClient();

  const key = reportKeys.validationChecks(clientId, reportId, latestProcessedVersionId ?? '');

  const mutationOptions: Omit<UseMutationOptions<void, unknown, any, unknown>, 'mutationFn' | 'mutationKey'> = {
    onMutate: async (body) => {
      await queryClient.cancelQueries({
        queryKey: reportKeys.validationChecks(clientId, reportId, latestProcessedVersionId ?? ''),
        exact: false,
      });

      const previousData = queryClient.getQueryData(key, {
        exact: false,
      });

      // Optimistically update to the new value
      queryClient.setQueriesData(
        { queryKey: reportKeys.validationChecks(clientId, reportId, latestProcessedVersionId ?? ''), exact: false },
        (old) => {
          return {
            ...old,
            pages: old.pages.map((page) => ({
              ...page,
              items: page.items.filter((item) => !body.checkIds?.includes(item.id)),
            })),
          };
        },
      );

      // Return a context object with the snapshotted value
      return { previousData };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueriesData(
        { queryKey: reportKeys.validationChecks(clientId, reportId, latestProcessedVersionId ?? ''), exact: false },
        context.previousData,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: reportKeys.validationChecks(clientId, reportId, latestProcessedVersionId ?? ''),
        exact: false,
        type: 'all',
      });
      queryClient.invalidateQueries(reportKeys.report(clientId, reportId));
      queryClient.invalidateQueries(reportKeys.version(clientId, reportId, latestProcessedVersionId ?? ''));
    },
  };

  const { mutate: applyActionsToAllChecks } = useApplyActionToAllChecks(
    clientId,
    reportId,
    latestProcessedVersionId ?? '',
    {
      onMutate: async (body) => {
        await queryClient.cancelQueries({
          queryKey: reportKeys.validationChecks(clientId, reportId, latestProcessedVersionId ?? ''),
          exact: false,
        });

        const previousData = queryClient.getQueryData(key, {
          exact: false,
        });

        // Optimistically update to the new value
        queryClient.setQueriesData(
          { queryKey: reportKeys.validationChecks(clientId, reportId, latestProcessedVersionId ?? ''), exact: false },
          (old) => {
            return {
              ...old,
              pages: old.pages.map((page) => ({
                ...page,
                items: [],
              })),
            };
          },
        );

        // Return a context object with the snapshotted value
        return { previousData };
      },
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      onError: (err, newTodo, context) => {
        queryClient.setQueriesData(
          { queryKey: reportKeys.validationChecks(clientId, reportId, latestProcessedVersionId ?? ''), exact: false },
          context.previousData,
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: reportKeys.validationChecks(clientId, reportId, latestProcessedVersionId ?? ''),
          exact: false,
          type: 'all',
        });
        queryClient.invalidateQueries(reportKeys.report(clientId, reportId));
        queryClient.invalidateQueries(reportKeys.version(clientId, reportId, latestProcessedVersionId ?? ''));
      },
    },
  );

  const { mutate: waive } = useWaiveChecks(clientId, reportId, latestProcessedVersionId ?? '', mutationOptions);
  const { mutate: flag } = useFlagChecks(clientId, reportId, latestProcessedVersionId ?? '', mutationOptions);
  const { mutate: markPending } = useMarkChecksPending(
    clientId,
    reportId,
    latestProcessedVersionId ?? '',
    mutationOptions,
  );
  const { mutate: markBelowThreshold } = useMarkChecksBelowThreshold(
    clientId,
    reportId,
    latestProcessedVersionId ?? '',
    mutationOptions,
  );

  const statusTab = params.status;

  const checkList = checks?.pages.flatMap((page) => page.items) ?? [];

  const fileTitle = visualExcels?.find((file) => file.reportFileId === reportFileId)?.filename;

  const loadMoreChecks = () => {
    if (!hasNextPage || isFetchingNextPage) return;
    fetchNextPage();
  };

  const observer = useIntersectionObserver((entry) => {
    if (!entry[0].isIntersecting) return;
    loadMoreChecks();
  });

  const getTotalChecksCount = (
    checks?: InfiniteData<FilteredListRepresentation<ReviewedValidationCheckRepresentation>>,
  ) => {
    // Count is the same across all pages, so take any
    return checks?.pages[0].count ?? -1;
  };

  const setChecked = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedIds((prev) => {
        const newArr = [...prev, id];
        if (checkList.length === getTotalChecksCount(checks))
          setSelectAll(checkList.every((item) => newArr.includes(item.id ?? '')));
        return newArr;
      });
      return;
    }
    setCheckedIds((prev) => prev.filter((item) => item !== id));
    setSelectAll(false);
  };

  const handleSelectRange = (versionParticlesFileId: string, sheet: string, range: CellRange) => {
    if (!versionFileMap.has(versionParticlesFileId)) {
      logger.error('There is no version particles file id %s mapped to a spreadsheet', versionParticlesFileId);
      return;
    }

    workbookHandle.current!.selectRange(versionFileMap.get(versionParticlesFileId)!, sheet, range);
  };

  const workbookHandlers = useMemo(
    () => ({
      updateFileId: (reportFileId: string) => {
        setReportFileId(reportFileId);
      },
    }),
    [],
  );

  useEffect(() => {
    if (selectAll) {
      setCheckedIds(checkList.map((item) => item.id ?? ''));
    }
  }, [checks]);

  const selectedChecksCount = selectAll ? getTotalChecksCount(checks) : checkedIds.length;

  if (report?.lifecycleStatus === ReportLifecycleStatus.Locked && !!latestVersion?.id)
    return <Redirect to={reportVersionReadonlyCheckPath(clientId, reportId, latestVersion.id)} />;

  return (
    <div>
      <Typography fontSize={24} component="h1">
        {isReportLoading ? <Skeleton variant="text" /> : report?.name}
      </Typography>
      <Tabs.Root
        value={statusTab}
        onValueChange={(value) => {
          setParams({ status: value as ValidationCheckReviewStatus });
          setSelectAll(false);
          setCheckedIds([]);
        }}
        className="mt-4"
      >
        <Tabs.List className="flex gap-1">
          <Tabs.Trigger className="basis-[16%]" value={ValidationCheckReviewStatus.Pending}>
            <div
              className={cx(
                'rounded-[4px] w-full border-t-[3px] border-orange bg-white p-2 flex flex-col items-start',
                statusTab === ValidationCheckReviewStatus.Pending &&
                  'border-b border-l border-r bg-orange bg-opacity-10',
              )}
            >
              <Typography fontSize={10}>To review</Typography>
              <Typography fontSize={20} className="w-full text-start">
                {isLatestProcessedVersionLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  Intl.NumberFormat().format(latestProcessedVersion?.pendingChecksCount ?? 0)
                )}
              </Typography>
            </div>
          </Tabs.Trigger>
          <Divider orientation="vertical" flexItem className="mx-2 border-[#DEDEDE]" />
          <Tabs.Trigger className="basis-[16%]" value={ValidationCheckReviewStatus.Flagged}>
            <div
              className={cx(
                'rounded-[4px] w-full border-t-[3px] border-[#EB8C00] bg-white p-2 flex flex-col items-start',
                statusTab === ValidationCheckReviewStatus.Flagged &&
                  'border-b border-l border-r bg-[#EB8C00] bg-opacity-10',
              )}
            >
              <Typography fontSize={10}>Flagged</Typography>
              <Typography fontSize={20} className="w-full text-start">
                {isLatestProcessedVersionLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  Intl.NumberFormat().format(latestProcessedVersion?.flaggedChecksCount ?? 0)
                )}
              </Typography>
            </div>
          </Tabs.Trigger>
          <Divider orientation="vertical" flexItem className="mx-2 border-[#DEDEDE]" />
          <Tabs.Trigger className="basis-[16%]" value={ValidationCheckReviewStatus.Waived}>
            <div
              className={cx(
                'rounded-[4px] w-full border-t-[3px] border-[#2B9AF3] bg-white p-2 flex flex-col items-start',
                statusTab === ValidationCheckReviewStatus.Waived &&
                  'border-b border-l border-r bg-[#2B9AF3] bg-opacity-10',
              )}
            >
              <Typography fontSize={10}>Waived</Typography>
              <Typography fontSize={20} className="w-full text-start">
                {isLatestProcessedVersionLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  Intl.NumberFormat().format(latestProcessedVersion?.waivedChecksCount ?? 0)
                )}
              </Typography>
            </div>
          </Tabs.Trigger>

          <Tabs.Trigger className="basis-[16%]" value={ValidationCheckReviewStatus.BelowThreshold}>
            <div
              className={cx(
                'rounded-[4px] w-full border-t-[3px] border-[#22992E] bg-white p-2 flex flex-col items-start',
                statusTab === ValidationCheckReviewStatus.BelowThreshold &&
                  'border-b border-l border-r bg-[#22992E] bg-opacity-10',
              )}
            >
              <Typography fontSize={10}>Passed below threshold</Typography>
              <Typography fontSize={20} className="w-full text-start">
                {isLatestProcessedVersionLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  Intl.NumberFormat().format(latestProcessedVersion?.belowThresholdChecksCount ?? 0)
                )}
              </Typography>
            </div>
          </Tabs.Trigger>
          <Tooltip title='to see all checks, download the Validation checks in the "Downloads" section'>
            <div className="basis-[16%]">
              <div className="rounded-[4px] w-full border-t-[3px] border-[#22992E] bg-white p-2 flex flex-col items-start">
                <Typography fontSize={10}>Passed</Typography>
                <Typography fontSize={20} className="w-full text-start">
                  {isLatestProcessedVersionLoading ? (
                    <Skeleton variant="text" />
                  ) : (
                    Intl.NumberFormat().format(latestProcessedVersion?.successfulChecksCount ?? 0)
                  )}
                </Typography>
              </div>
            </div>
          </Tooltip>

          <Divider orientation="vertical" flexItem className="mx-2 border-[#DEDEDE]" />
          <Tooltip title='to see all checks, download the Validation checks in the "Downloads" section'>
            <div className="basis-[16%]">
              <div className="rounded-[4px] w-full border-t-[3px] border-text-dark-grey bg-white p-2 flex flex-col items-start">
                <Typography fontSize={10}>Total</Typography>
                <Typography fontSize={20} className="w-full text-start">
                  {isLatestProcessedVersionLoading ? (
                    <Skeleton variant="text" />
                  ) : (
                    Intl.NumberFormat().format(latestProcessedVersion?.validationChecksCount ?? 0)
                  )}
                </Typography>
              </div>
            </div>
          </Tooltip>
        </Tabs.List>
      </Tabs.Root>
      <div className="flex justify-between items-center">
        <div className="mt-4 flex gap-4 flex-wrap">
          <TextField
            className="w-[321px]"
            label="Search checks"
            value={params.search ?? ''}
            onChange={(e) => {
              setParams({ search: e.target.value });
            }}
          />
          <FormControl className="w-[160px]">
            <InputLabel>Source</InputLabel>

            <Select
              value={params.source ?? ''}
              onChange={(e) => {
                setParams({ source: e.target.value });
              }}
              label="Source"
            >
              {sourceOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Paper className="w-[180px] h-[45px] py-1 px-2 flex gap-1 mt-1">
            <Select
              classes={{ select: '!py-1.5 !pl-1.5' }}
              value={params.thresholdFilter}
              onChange={(e) => {
                setParams({ thresholdFilter: e.target.value as ThresholdFilter });
              }}
            >
              {thresholdSelectOptions.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
            <InputBase
              type="number"
              className="w-[160px]"
              placeholder="Threshold"
              // passing ' ' to show empty string, because '' is the same as undefined for InputBase
              value={params.threshold === '' ? ' ' : params.threshold ?? ''}
              onChange={(e) => {
                if (Number.isNaN(+e.target.value)) return;
                setParams({ threshold: e.target.value });
              }}
            />
          </Paper>

          <FormControl className="w-[160px]">
            <InputLabel>Subject</InputLabel>

            <Select
              value={params.subject ?? ''}
              onChange={(e) => {
                setParams({ subject: e.target.value });
              }}
              label="Subject"
            >
              {subjectOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!!visualExcels?.length ? (
            <FormControl className="w-[160px]">
              <InputLabel>File</InputLabel>

              <Select
                defaultValue={visualExcels?.[0].reportFileId}
                value={reportFileId ?? ''}
                onChange={(e) => {
                  workbookHandle.current?.setActiveFileId(e.target.value ?? '');
                  workbookHandle.current?.clearSelection();
                }}
                label="File"
              >
                {visualExcels?.map((item) => (
                  <MenuItem key={item.reportFileId} value={item.reportFileId}>
                    {item.filename}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}

          <Button
            className="bg-orange text-white h-[45px] mt-1"
            disabled={!search && !source && !threshold && !subject && thresholdFilter === 'gt'}
            variant="contained"
            onClick={() => {
              setParams({
                search: undefined,
                source: undefined,
                threshold: undefined,
                subject: undefined,
                thresholdFilter: 'gt',
              });
            }}
          >
            Reset
          </Button>
          <Show when={!!search || !!source || !!threshold || !!subject}>
            <div className="flex items-center">
              <Typography className="text-sm font-medium">Filter Result: {checks?.pages.at(-1)?.count}</Typography>
            </div>
          </Show>
        </div>
        <FormControl>
          <div className="flex items-center mr-4">
            <Checkbox
              id="selectAll"
              checked={selectAll}
              onChange={() => {
                setSelectAll((prevSelectAll) => {
                  if (prevSelectAll) {
                    setCheckedIds([]);
                  } else {
                    setCheckedIds(checkList.map((item) => item.id ?? ''));
                  }
                  return !prevSelectAll;
                });
              }}
            />
            <FormLabel htmlFor="selectAll">
              <Typography fontSize={14} className="font-bold text-[#000000]">
                Select all
                {checkedIds.length ? ` (${selectAll ? getTotalChecksCount(checks) : checkedIds.length})` : ''}
              </Typography>
            </FormLabel>
          </div>
        </FormControl>
      </div>

      <div className="flex gap-2">
        <div className="basis-1/2 w-1/2 mt-4">
          <Switch>
            <Match when={visualExcels?.length! > 0}>
              <div className="sticky top-0">
                <Typography component="h1" className={fileTitle ? 'block mb-4 font-medium text-xl' : 'hidden'}>
                  {fileTitle}
                </Typography>
                <Workbook handlers={workbookHandlers} ref={workbookHandle} fileList={visualExcels ?? []} />
              </div>
            </Match>
            <Match when={isVisualExcelListLoading}>
              <Skeleton variant="rectangular" width="100%" height={500} className="rounded-[4px]" />
            </Match>
          </Switch>
        </div>
        <div className="mt-[60px] flex flex-col gap-1 basis-1/2">
          <Show when={isChecksFetching}>
            <LinearProgress />
          </Show>
          {checkList.map((item) => (
            <li
              key={item.id}
              className="list-none"
              ref={(node) => {
                if (item.id !== checkList.at(-1)?.id || !node) return;

                if (lastCardRef.current) {
                  observer.unobserve(lastCardRef.current);
                }

                if (!hasNextPage) return;
                lastCardRef.current = node;
                observer.observe(node);
              }}
            >
              <CheckCard
                item={item}
                checkList={checkList}
                setChecked={setChecked}
                checked={checkedIds.includes(item?.id ?? '')}
                handleSelectRange={handleSelectRange}
                setOpenedCheckId={setOpenedCheckId}
                open={item.id === openedCheckId}
              />
            </li>
          ))}
          <Show when={isFetchingNextPage || isChecksLoading}>
            <>
              {new Array(isChecksLoading ? 10 : 3).fill(null).map(() => (
                <Skeleton variant="rectangular" width="100%" height={124} className="rounded-[4px]" />
              ))}
            </>
          </Show>
        </div>
      </div>
      <Show when={!!checkedIds.length}>
        <div className="fixed bottom-0 left-0 right-0 py-3 px-6 bg-white border border-secondary-main flex gap-2 justify-end">
          <Button
            variant="contained"
            onClick={() => {
              setSelectAll(false);
              setCheckedIds([]);
            }}
          >
            Cancel
          </Button>
          <Show when={params.status !== 'Flagged'}>
            <Button
              variant="contained"
              onClick={() => {
                setOpenMotivationDialog(true);
                setStatus('flagged');
              }}
            >
              Flag {`${selectedChecksCount} ${pluralRuleToChecks.get(enCardinalRules.select(selectedChecksCount))}`}
            </Button>
          </Show>
          <Show when={params.status !== 'Waived'}>
            <Button
              variant="contained"
              onClick={() => {
                setOpenMotivationDialog(true);
                setStatus('waived');
              }}
            >
              Waive {`${selectedChecksCount} ${pluralRuleToChecks.get(enCardinalRules.select(selectedChecksCount))}`}
            </Button>
          </Show>
          <Show when={params.status !== 'BelowThreshold'}>
            <Button
              variant="contained"
              onClick={() => {
                setOpenMotivationDialog(true);
                setStatus('belowThreshold');
              }}
            >
              Mark {`${selectedChecksCount} ${pluralRuleToChecks.get(enCardinalRules.select(selectedChecksCount))}`}{' '}
              below threshold
            </Button>
          </Show>
          <Show when={params.status !== 'Pending'}>
            <Button
              variant="contained"
              onClick={() => {
                if (selectAll) {
                  applyActionsToAllChecks({
                    body: {
                      action: ValidationChecksAction.Flag,
                      filterHash: latestFilterHash,
                    },
                  });
                } else {
                  markPending({ checkIds: checkedIds });
                }
                setSelectAll(false);
                setCheckedIds([]);
              }}
            >
              Mark {`${selectedChecksCount} ${pluralRuleToChecks.get(enCardinalRules.select(selectedChecksCount))}`} to
              review
            </Button>
          </Show>
        </div>
      </Show>
      <Dialog
        open={openMotivationDialog && !!status}
        onClose={() => {
          setOpenMotivationDialog(false);
        }}
      >
        <DialogTitle className="p-0 flex items-center justify-between text-[20px]">
          <Typography fontSize="inherit" className="text-dark">
            Motivation
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenMotivationDialog(false);
            }}
          >
            <CloseIcon fontSize="inherit" className="text-light" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="p-0">
          <TextField
            error={motivationError}
            helperText={motivationError ? 'Required' : ''}
            className="w-full max-w-[800px] mt-4"
            InputProps={{ classes: { root: 'p-2' } }}
            value={motivation}
            onChange={(e) => {
              if (e.target.value) {
                setMotivationError(false);
              } else {
                if (status === 'waived' || status === 'belowThreshold') {
                  setMotivationError(true);
                }
              }
              setMotivation(e.target.value);
            }}
            rows={3}
            multiline
            placeholder="Motivation..."
          />
        </DialogContent>
        <DialogActions className="p-0 mt-6 flex">
          <Button
            className="py-3 basis-1/2"
            onClick={() => {
              setOpenMotivationDialog(false);
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className="py-3 basis-1/2"
            type="submit"
            variant="contained"
            onClick={() => {
              if (status === 'waived') {
                if (!motivation) return;
                if (selectAll) {
                  applyActionsToAllChecks({
                    body: { action: ValidationChecksAction.Waive, filterHash: latestFilterHash, motivation },
                  });
                } else {
                  waive({ motivation, checkIds: checkedIds });
                }
                setSelectAll(false);
                setStatus(null);
                setCheckedIds([]);
              }
              if (status === 'belowThreshold') {
                if (!motivation) return;
                if (selectAll) {
                  applyActionsToAllChecks({
                    body: {
                      action: ValidationChecksAction.MarkBelowThreshold,
                      filterHash: latestFilterHash,
                      motivation,
                    },
                  });
                } else {
                  markBelowThreshold({ motivation, checkIds: checkedIds });
                }
                setSelectAll(false);
                setStatus(null);
                setCheckedIds([]);
              }
              if (status === 'flagged') {
                if (selectAll) {
                  applyActionsToAllChecks({
                    body: {
                      action: ValidationChecksAction.Flag,
                      filterHash: latestFilterHash,
                      motivation,
                    },
                  });
                } else {
                  flag({ motivation, checkIds: checkedIds });
                }
                setSelectAll(false);
                setStatus(null);
                setCheckedIds([]);
              }
              setMotivation('');
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LatestChecksPage;
