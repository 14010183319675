import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import {
  clientCompareFilesPath,
  clientComparisonsPath,
  clientDashboardPath,
  clientPath,
  clientsPagePath,
  clientsPath,
  comparisonDetails,
  reportPath,
  reportsPath,
} from '~/app/constants/url/auditor';
import { actions, selectors, useDispatch, useSelector } from '~/data/index.js';
import { useEffect } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { Dashboard } from './dashboard/index';
import { ReportRouter } from './report/index';
import { Reports } from './reports/index';
import Comparison from './report/report/Comparison';
import CompareFiles from './report/report/CompareFiles';
import ComparisonDetailsPage from '~/app/components/pages/auditor/clients/client/comparisons/[comparisonId]/page';
import ComparisonFileLogsPage from '~/app/components/pages/auditor/clients/client/comparison-files/[fileId]/logs/page';
import ComparisonLogsPage from '~/app/components/pages/auditor/clients/client/comparisons/[comparisonId]/logs/page';
import { supportPaths } from '~/app/constants/url/shared';
import { Skeleton } from '@mui/material';

const ClientReportsRouter = () => {
  const dispatch = useDispatch();
  const { clientId, comparisonId } = useParams<{ clientId: string; comparisonId?: string }>();

  const client = useSelector((state) => selectors.clients.getItem(state, clientId));
  const isClientLoaded = useSelector(selectors.clients.itemLoaded);

  const fetchClient = () => dispatch(actions.clients.fetchClient(clientId));
  const fetchClientScopes = () => dispatch(actions.scopes.fetchClientScopes(clientId, undefined));
  const clearSingle = () => dispatch(actions.clients.clearSingle());
  const clear = () => dispatch(actions.scopes.clear());

  useEffect(() => {
    if (!isClientLoaded) {
      fetchClient();
      fetchClientScopes();
    }
    return () => {
      clear();
      clearSingle();
    };
  }, []);

  if (isClientLoaded && !client) {
    return <Redirect to={clientsPath} />;
  }

  return (
    <>
      <Breadcrumbs.Anchor
        path={clientPath(':clientId')}
        link={clientsPagePath(1)}
        component={
          <span title="Client">{isClientLoaded ? client.get('name') : <Skeleton width={100} variant="text" />}</span>
        }
      />
      <Switch>
        <Route exact path={clientDashboardPath(':clientId')}>
          <Breadcrumbs.Anchor
            key="dashboard"
            path={clientDashboardPath(':clientId')}
            link={clientDashboardPath(':clientId')}
            component={<span>Dashboard</span>}
          />
          <Dashboard />
        </Route>
        <Route exact path={reportsPath(':clientId')}>
          <Breadcrumbs.Anchor
            key="reports"
            path={reportsPath(':clientId')}
            link={reportsPath(':clientId')}
            component={<span>Reports</span>}
          />
          <Reports />
        </Route>

        <Route path={clientComparisonsPath(':clientId')}>
          <Breadcrumbs.Anchor
            key="comparisons"
            path={clientComparisonsPath(clientId ?? '')}
            link={clientComparisonsPath(clientId ?? '')}
            component={<span>Comparisons</span>}
          />
          <Switch>
            <Route path={supportPaths.comparisonFile(':clientId', ':fileId')}>
              <ComparisonFileLogsPage />
            </Route>

            <Route path={supportPaths.comparison(':clientId', ':comparisonId')}>
              <ComparisonLogsPage />
            </Route>
            <Route exact path={clientCompareFilesPath(':clientId')}>
              <Breadcrumbs.Anchor
                path={clientCompareFilesPath(clientId ?? '')}
                link={clientCompareFilesPath(clientId ?? '')}
                component={<span>Compare</span>}
              />
              <CompareFiles />
            </Route>
            <Route exact path={comparisonDetails(':clientId', ':comparisonId')}>
              <ComparisonDetailsPage />
            </Route>

            <Route exact path={clientComparisonsPath(':clientId')}>
              <Comparison />
            </Route>
          </Switch>
        </Route>
        <Route path={reportPath(':clientId', ':reportId')} component={ReportRouter} />
        <Redirect from={reportsPath(clientId)} to={reportsPath(clientId)} />
      </Switch>
    </>
  );
};

export default ClientReportsRouter;
