import React, { useMemo, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import cx from '~/data/utils/helpers/cx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ReviewedValidationCheckRepresentation,
  ValidationCheckReviewStatus,
  VersionRepresentation,
  VersionStatus,
} from '~/data/openapi-client/index';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import { Show } from '~/app/components/core/show';
import {
  reportKeys,
  useFlagChecks,
  useGetReport,
  useGetVersions,
  useMarkChecksBelowThreshold,
  useMarkChecksPending,
  useWaiveChecks,
} from '~/data/reports/queries';
import { useParams } from 'react-router-dom';
import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { Paper } from '@mui/material';
import { CellRange } from '~/app/components/visual-excel/worksheet';
import sortBy from 'lodash/sortBy';

const statusMapper = {
  [ValidationCheckReviewStatus.Pending]: 'To review',
  [ValidationCheckReviewStatus.Flagged]: 'Flagged',
  Passed: 'Passed',
  [ValidationCheckReviewStatus.BelowThreshold]: 'Below threshold',
  [ValidationCheckReviewStatus.Waived]: 'Waived',
  Total: 'Total',
};

const statusTagClasses = {
  [ValidationCheckReviewStatus.Pending]: 'text-orange bg-orange bg-opacity-10',
  [ValidationCheckReviewStatus.Flagged]: 'text-[#EB8C00] bg-[#EB8C00] bg-opacity-10',
  Passed: 'text-[#22992E] bg-[#22992E] bg-opacity-10',
  [ValidationCheckReviewStatus.BelowThreshold]: 'text-[#22992E] bg-[#22992E] bg-opacity-10',
  [ValidationCheckReviewStatus.Waived]: 'text-[#2B9AF3] bg-[#2B9AF3] bg-opacity-10',
  Total: 'text-text-dark-grey bg-text-dark-grey bg-opacity-10',
};

const statusCardClasses = {
  [ValidationCheckReviewStatus.Pending]: 'border-orange',
  [ValidationCheckReviewStatus.Flagged]: 'border-[#EB8C00]',
  Passed: 'border-[#22992E]',
  [ValidationCheckReviewStatus.BelowThreshold]: 'border-[#22992E]',
  [ValidationCheckReviewStatus.Waived]: 'border-[#2B9AF3]',
  Total: 'border-text-dark-grey',
};

interface CheckCardProps {
  item: ReviewedValidationCheckRepresentation;
  checked: boolean;
  setChecked: (checked: boolean, id: string) => void;
  handleSelectRange: (versionParticlesFileId: string, sheet: string, range: CellRange) => void;
  setOpenedCheckId: (checkId: string | null) => void;
  open: boolean;
  checkList: ReviewedValidationCheckRepresentation[];
}

export const CheckCard = ({
  item,
  checked,
  setChecked,
  handleSelectRange,
  setOpenedCheckId,
  open,
  checkList,
}: CheckCardProps) => {
  const { clientId = '', reportId = '' } = useParams<{ clientId?: string; reportId?: string }>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [motivation, setMotivation] = useState(item.motivation ?? '');
  const [motivationError, setMotivationError] = useState(false);

  const versionParticlesFileId = item.versionParticlesFileId!;

  const { data: report, isInitialLoading: isReportLoading } = useGetReport(clientId, reportId);
  const { data: latestVersion } = useGetVersions(clientId, reportId, 'latest');

  const reportVersions: VersionRepresentation[] = sortBy(report?.versions, (v) => v.order)?.reverse();
  const latestProcessedVersionId =
    latestVersion?.status === VersionStatus.Draft
      ? reportVersions.find((v) => v.validationChecksCount > 0)?.id
      : latestVersion?.id;

  const queryClient = useQueryClient();

  const key = reportKeys.validationChecks(clientId, reportId, latestProcessedVersionId ?? '');

  const mutationOptions = useMemo(
    (): Omit<UseMutationOptions<void, unknown, any, unknown>, 'mutationFn' | 'mutationKey'> => ({
      onMutate: async (body) => {
        await queryClient.cancelQueries({
          queryKey: reportKeys.validationChecks(clientId, reportId, latestProcessedVersionId ?? ''),
          exact: false,
        });

        const previousData = queryClient.getQueryData(key, {
          exact: false,
        });

        // Optimistically update to the new value
        queryClient.setQueriesData(
          { queryKey: reportKeys.validationChecks(clientId, reportId, latestProcessedVersionId ?? ''), exact: false },
          (old) => {
            return {
              ...old,
              pages: old.pages.map((page) => ({
                ...page,
                items: page.items.filter((item) => !body.checkIds?.includes(item.id)),
              })),
            };
          },
        );

        const checkIdsPositions: undefined | number[] = body.checkIds?.map((id) =>
          checkList.findIndex((item) => item.id === id),
        );

        if (checkIdsPositions) {
          const maxPos = Math.max(...checkIdsPositions);
          const nextCheck = checkList.at(maxPos + 1);
          setOpenedCheckId(nextCheck?.id ?? null);
        }

        // Return a context object with the snapshotted value
        return { previousData };
      },
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      onError: (err, newTodo, context) => {
        queryClient.setQueriesData(
          { queryKey: reportKeys.validationChecks(clientId, reportId, latestProcessedVersionId ?? ''), exact: false },
          context.previousData,
        );
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: reportKeys.validationChecks(clientId, reportId, latestProcessedVersionId ?? ''),
          exact: false,
        });
        queryClient.invalidateQueries(reportKeys.report(clientId, reportId));
        queryClient.invalidateQueries(reportKeys.version(clientId, reportId, latestProcessedVersionId ?? ''));
      },
    }),
    [checkList],
  );

  const { mutate: waive } = useWaiveChecks(clientId, reportId, latestProcessedVersionId ?? '', mutationOptions);
  const { mutate: flag } = useFlagChecks(clientId, reportId, latestProcessedVersionId ?? '', mutationOptions);
  const { mutate: markPending } = useMarkChecksPending(
    clientId,
    reportId,
    latestProcessedVersionId ?? '',
    mutationOptions,
  );
  const { mutate: markBelowThreshold } = useMarkChecksBelowThreshold(
    clientId,
    reportId,
    latestProcessedVersionId ?? '',
    mutationOptions,
  );

  const getExpression = (expression: string, cellValues: ReviewedValidationCheckRepresentation['cellValues']) => {
    const array: { value: string; visualExcelMapping?: { fileId: string; sheet: string; range: CellRange } }[] = [];
    let prevIndex = 0;

    cellValues?.forEach((val, i) => {
      if (val.expression) {
        const startIndex = expression.indexOf(val.expression);
        const endIndex = startIndex + val.expression.length;
        array.push({ value: expression.slice(prevIndex, startIndex) });
        array.push({
          value: val.expression,
          visualExcelMapping: {
            fileId: val.combinationFileId ?? versionParticlesFileId,
            sheet: val.sheet ?? '',
            range: {
              start: { row: Number(val.row) - 1, col: Number(val.column) - 1 },
              end: { row: Number(val.row) - 1, col: Number(val.column) - 1 },
            },
          },
        });

        if (i === cellValues.length - 1) {
          array.push({ value: expression.slice(endIndex) });
        }
        prevIndex = endIndex;
      }
    });

    if (!array.length) return expression;

    return array.map((item, i) => (
      <>
        {item.visualExcelMapping ? (
          <span
            role="button"
            className="underline"
            data-expression={i}
            onClick={() => {
              if (item.visualExcelMapping) {
                handleSelectRange(
                  item.visualExcelMapping.fileId,
                  item.visualExcelMapping.sheet,
                  item.visualExcelMapping.range,
                );
              }
            }}
          >
            {item.value}
          </span>
        ) : (
          item.value
        )}
      </>
    ));
  };

  const getSubstitution = (substitution: string, cellValues: ReviewedValidationCheckRepresentation['cellValues']) => {
    const array: { value: string; visualExcelMapping?: { fileId: string; sheet: string; range: CellRange } }[] = [];
    let prevIndex = 0;

    cellValues?.forEach((val, i) => {
      const stringVal = val.value?.toString();
      if (stringVal) {
        const startIndex = substitution.indexOf(stringVal);
        const endIndex = startIndex + stringVal.length;
        array.push({ value: substitution.slice(prevIndex, startIndex) });
        array.push({
          value: stringVal,
          visualExcelMapping: {
            fileId: val.combinationFileId ?? versionParticlesFileId,
            sheet: val.sheet ?? '',
            range: {
              start: { row: Number(val.row) - 1, col: Number(val.column) - 1 },
              end: { row: Number(val.row) - 1, col: Number(val.column) - 1 },
            },
          },
        });
        if (i === cellValues.length - 1) {
          array.push({ value: substitution.slice(endIndex) });
        }
        prevIndex = endIndex;
      }
    });

    if (!array.length) return substitution;

    return array.map((item, i) => (
      <>
        {item.visualExcelMapping ? (
          <span
            role="button"
            className="underline"
            onClick={() => {
              if (item.visualExcelMapping) {
                handleSelectRange(
                  item.visualExcelMapping.fileId,
                  item.visualExcelMapping.sheet,
                  item.visualExcelMapping.range,
                );
              }
            }}
          >
            {item.value}
          </span>
        ) : (
          item.value
        )}
      </>
    ));
  };

  const checkbox = (
    <div className={cx('flex items-center', open && 'items-end flex-col grow')}>
      <div className="flex items-center">
        <Checkbox
          className="[&_svg]:rotate-0"
          checked={checked}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            if (!item.id) return;
            setChecked(e.target.checked, item.id);
          }}
        />

        <div>
          <button
            aria-describedby="status-popover"
            className={cx('px-[7px] py-[1px] rounded-sm', statusTagClasses[item.status as ValidationCheckReviewStatus])}
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          >
            <Typography className="whitespace-nowrap" fontSize={12}>
              {statusMapper[item.status as ValidationCheckReviewStatus]}
            </Typography>
          </button>
          <Popover
            className="p-2"
            id="status-popover"
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Typography fontSize={12}>Set check status</Typography>
            <div className="flex flex-col gap-1">
              <Show when={item.status !== ValidationCheckReviewStatus.Pending}>
                <button
                  className={cx(
                    'px-[7px] py-[1px] rounded-sm w-full flex justify-center',
                    statusTagClasses[ValidationCheckReviewStatus.Pending],
                  )}
                  onClick={() => {
                    if (!item.id) return;
                    markPending({ checkIds: [item.id] });
                  }}
                >
                  <Typography fontSize={12}>{statusMapper[ValidationCheckReviewStatus.Pending]}</Typography>
                </button>
              </Show>
              <Show when={item.status !== ValidationCheckReviewStatus.Waived}>
                <button
                  className={cx(
                    'px-[7px] py-[1px] rounded-sm w-full flex justify-center',
                    statusTagClasses[ValidationCheckReviewStatus.Waived],
                  )}
                  onClick={() => {
                    if (!motivation) {
                      setMotivationError(true);
                      setOpenedCheckId(item.id ?? null);
                      return;
                    }
                    if (!item.id) return;
                    waive({ motivation, checkIds: [item.id] });
                  }}
                >
                  <Typography fontSize={12}>{statusMapper[ValidationCheckReviewStatus.Waived]}</Typography>
                </button>
              </Show>
              <Show when={item.status !== ValidationCheckReviewStatus.Flagged}>
                <button
                  className={cx(
                    'px-[7px] py-[1px] rounded-sm w-full flex justify-center',
                    statusTagClasses[ValidationCheckReviewStatus.Flagged],
                  )}
                  onClick={() => {
                    if (!item.id) return;
                    flag({ motivation, checkIds: [item.id] });
                  }}
                >
                  <Typography fontSize={12}>{statusMapper[ValidationCheckReviewStatus.Flagged]}</Typography>
                </button>
              </Show>
              <Show when={item.status !== ValidationCheckReviewStatus.BelowThreshold}>
                <button
                  className={cx(
                    'px-[7px] py-[1px] rounded-sm w-full flex justify-center',
                    statusTagClasses[ValidationCheckReviewStatus.BelowThreshold],
                  )}
                  onClick={() => {
                    if (!motivation) {
                      setMotivationError(true);
                      setOpenedCheckId(item.id ?? null);
                      return;
                    }
                    if (!item.id) return;
                    markBelowThreshold({ motivation, checkIds: [item.id] });
                  }}
                >
                  <Typography fontSize={12}>{statusMapper[ValidationCheckReviewStatus.BelowThreshold]}</Typography>
                </button>
              </Show>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );

  return (
    <Paper
      className={cx(
        'border-0 border-r-[3px] flex py-0 pl-0 pr-6',
        open && 'pr-0',
        statusCardClasses[item.status as ValidationCheckReviewStatus],
      )}
    >
      <Accordion
        className="border-0"
        classes={{
          root: 'px-6 py-4',
          expanded: '!m-0',
        }}
        expanded={!!open}
        onChange={(e, expended) => {
          const val = item?.cellValues?.[0];
          if (expended && val) {
            const sheet = val.sheet;
            const cell = { row: Number(val.row) - 1, col: Number(val.column) - 1 };

            handleSelectRange(val.combinationFileId || versionParticlesFileId, sheet ?? '', {
              start: { ...cell },
              end: { ...cell },
            });
          }
          setOpenedCheckId(expended ? item.id ?? null : null);
        }}
      >
        <AccordionSummary
          classes={{
            root: 'p-0 min-h-0',
            content: 'm-0',
            expanded: '[&_svg]:rotate-180 !m-0 !min-h-0',
          }}
        >
          <div className="w-full flex justify-between">
            <div>
              <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center">
                  <ExpandMoreIcon fontSize="medium" className="text-orange" />
                  <Typography fontSize={14} className="m-0 text-[#333333]">
                    Amounts
                  </Typography>
                </div>
              </div>
              <div className="flex gap-6 ml-8 mt-2">
                <div className="flex flex-col items-start">
                  <Typography fontSize={14} className="font-bold text-text-dark-grey">
                    Check code
                  </Typography>
                  <Typography fontSize={14} className="text-text-dark-grey normal-case">
                    {item.code}
                  </Typography>
                </div>
                <div className="flex flex-col items-start">
                  <Typography fontSize={14} className="font-bold text-text-dark-grey">
                    Description
                  </Typography>
                  <Typography fontSize={14} className="text-text-dark-grey">
                    Check that profit or loss for the year equals the sum of each of its main categories
                  </Typography>
                </div>
                <div className="flex flex-col items-start">
                  <Typography fontSize={14} className="font-bold text-text-dark-grey">
                    Difference
                  </Typography>
                  <Typography fontSize={14} className="text-text-dark-grey">
                    {item.difference ? Intl.NumberFormat().format(+item.difference) : '-'}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="ml-4 pr-0 pb-0">
          <div className="flex justify-between items-end">
            <div className="w-full">
              <div>
                <Typography fontSize={14} className="font-bold">
                  Expression
                </Typography>
                <Typography fontSize={14} fontFamily="Roboto">
                  {item.expression ? getExpression(item.expression, item.cellValues) : '-'}
                </Typography>
              </div>
              <div className="mt-2">
                <Typography fontSize={14} className="font-bold">
                  Result
                </Typography>
                <Typography fontSize={14} fontFamily="Roboto">
                  {item.substitution ? getSubstitution(item.substitution, item.cellValues) : '-'}
                </Typography>
              </div>
              <TextField
                error={motivationError}
                helperText={motivationError ? 'Required' : ''}
                className="w-full max-w-[800px] mt-4"
                InputProps={{ classes: { root: 'p-2' } }}
                defaultValue={item.motivation}
                value={motivation}
                onChange={(e) => {
                  if (e.target.value) {
                    setMotivationError(false);
                  }
                  setMotivation(e.target.value);
                }}
                rows={3}
                multiline
                placeholder="Motivation..."
              />
            </div>
          </div>
          <div className="flex flex-end w-full mt-1">{open && checkbox}</div>
        </AccordionDetails>
      </Accordion>
      {!open && checkbox}
    </Paper>
  );
};
