import React from 'react';
import { withRouter } from 'react-router-dom';
import { Icon } from '~/ui/index.js';

import s from './styles.module.scss';

type footerProps = {
  children?: any;
};
const Footer = ({ children }: footerProps) => (
  <footer className={s.footer}>
    <div className={s.footerDisclaimerWrapper}>
      <p className={s.footerDisclaimer}>
        <Icon className={s.footerDisclaimerIcon} icon={Icon.icons.copyright} /> {new Date().getFullYear()} PwC. All
        rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.
      </p>
    </div>
    <div className={s.footerWrapper}>{children}</div>
  </footer>
);

Footer.defaultProps = {
  children: null,
};

export default withRouter(Footer as any);
