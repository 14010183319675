import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { Text } from '~/ui/index.js';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { actions, selectors, useDispatch, useSelector } from '~/data/index.js';
import { useHistory, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Search from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import { useDebounceValue } from '~/app/utils/debounce';
import Loader from '~/app/components/core/preloader';
import { manageClientPath } from '~/app/constants/url/auditor';
import ThreeDotsLoader from '~/ui/components/three-dots-loader';
import s from './styles.module.scss';
import Modal from '~/ui/components/modal/index';

interface AvailableUsersPopupProps {
  closeFunction: () => void;
}

const AvailableUsersPopup = ({ closeFunction }: AvailableUsersPopupProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { clientId } = useParams<{ clientId: string }>();

  const availableUsers = useSelector(selectors.users.getAvailableUsers);
  const isAvailableUsersLoaded = useSelector(selectors.users.availableUsersLoaded);
  const totalItems = useSelector(selectors.users.getTotalAvailableUsers);

  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [error, setError] = useState('');

  const debouncedSearchText = useDebounceValue(search);

  const searchParams = {
    search: debouncedSearchText,
    sort: 'FamilyName',
    direction: 'ASC',
    limit: 10,
    offset,
  };

  const fetchAvailableUsers = (filter) => dispatch(actions.users.fetchAvailableUsers(filter, clientId));
  const clear = () => dispatch(actions.users.clear());
  const connectUsers = (values) => dispatch(actions.users.connectUsers(clientId, values));

  useEffect(() => {
    if (!isAvailableUsersLoaded) {
      fetchAvailableUsers({ ...searchParams, offset: 0 });
      setOffset(offset + 10);
    }
    return () => {
      clear();
    };
  }, []);

  const fetchNext = () => {
    fetchAvailableUsers(searchParams);
    setOffset(offset + 10);
  };

  const handleOnSearch = () => {
    clear();
    setOffset(10);
    fetchAvailableUsers({ ...searchParams, offset: 0 });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedUsers(availableUsers.toJS());
      return;
    }
    setSelectedUsers([]);
  };

  // @ts-expect-error
  const isSelected = (id: string) => !!selectedUsers.find((el) => el.id === id);

  const handleClick = (event: React.MouseEvent<unknown>, user) => {
    const selectedIndex = selectedUsers.map((el) => el.id).indexOf(user.id);
    let newSelected: string[] = [];

    switch (selectedIndex) {
      case -1:
        newSelected = newSelected.concat(selectedUsers, user);
        break;
      case 0:
        newSelected = newSelected.concat(selectedUsers.slice(1));
        break;
      case selectedUsers.length - 1:
        newSelected = newSelected.concat(selectedUsers.slice(0, -1));
        break;
      default:
        newSelected = newSelected.concat(selectedUsers.slice(0, selectedIndex), selectedUsers.slice(selectedIndex + 1));
        break;
    }

    setSelectedUsers(newSelected);
  };

  const handleConnectClients = async () => {
    if (!selectedUsers.length) {
      setError('Please select client');
    } else {
      const res = await connectUsers({ emails: selectedUsers.map((el) => el.email) });
      if (res) {
        history.push(manageClientPath(clientId));
      }
    }
  };

  return (
    <Modal
      open
      closeIcon
      maxWidth="md"
      onClose={closeFunction}
      modalTitle={<Text weight={Text.weight.medium}>Add user access</Text>}
    >
      <Box marginBottom="20px" display="flex">
        <Box marginRight="20px" display="flex" flexGrow="1">
          <TextField
            placeholder="Search user"
            InputProps={{
              startAdornment: <Search htmlColor={Text.color.light} />,
            }}
            onChange={({ target: { value } }) => setSearch(value)}
            value={search}
            size="small"
            color="primary"
            fullWidth
          />
        </Box>
        <Button variant="contained" onClick={handleOnSearch}>
          Search
        </Button>
      </Box>
      <Box id="scrollableDiv" style={{ height: 400, overflow: 'auto' }}>
        <TableContainer sx={{ maxHeight: 390 }}>
          <InfiniteScroll
            dataLength={availableUsers?.size || 0}
            hasMore={availableUsers?.size !== totalItems}
            next={() => fetchNext()}
            loader={<ThreeDotsLoader />}
            height={390}
            style={{ width: '100%' }}
            scrollableTarget="scrollableDiv"
          >
            {!isAvailableUsersLoaded && !availableUsers?.size ? (
              <Loader />
            ) : (
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell width="10%" key="checkbox">
                      <Checkbox
                        size="small"
                        color="primary"
                        indeterminate={selectedUsers.length > 0 && selectedUsers.length < availableUsers?.size}
                        checked={availableUsers?.size > 0 && selectedUsers.length === availableUsers?.size}
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>

                    <TableCell key="name" width="30%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Name
                      </Text>
                    </TableCell>

                    <TableCell key="familyName" width="40%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Family name
                      </Text>
                    </TableCell>
                    <TableCell key="familyName" width="30%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Email
                      </Text>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {availableUsers?.map((user) => {
                    const isItemSelected = isSelected(user.get('id'));
                    return (
                      <TableRow
                        tabIndex={-1}
                        key={user.id}
                        onClick={(event) => handleClick(event, user.toJS())}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox size="small" color="primary" checked={isItemSelected} />
                        </TableCell>

                        <TableCell>
                          <Text size={Text.size.s}>{user.get('firstName')}</Text>
                        </TableCell>
                        <TableCell>
                          <Text size={Text.size.s}>{user.get('familyName')}</Text>
                        </TableCell>
                        <TableCell>
                          <Text size={Text.size.s}>{user.get('email')}</Text>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </InfiniteScroll>
        </TableContainer>
      </Box>
      <Box padding="0 16px">
        <Text size={Text.size.xs} color={Text.color.red}>
          {error}
        </Text>
      </Box>
      <Box className={s.formButtonsWrap}>
        <Button onClick={closeFunction} variant="outlined">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleConnectClients}>
          Select
        </Button>
      </Box>
    </Modal>
  );
};

export default AvailableUsersPopup;
