import ky from 'ky';
import { useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as XLSX from '~/vendor/xlsx/xlsx';
import { utils } from '~/vendor/xlsx/xlsx';
import { baseURL } from '~/config';
import { api } from '~/data/dazzler-api';
import hoursToMilliseconds from 'date-fns/hoursToMilliseconds';
import { connection, db, EOS, executeCompiledQuery } from '~/db';
import {
  type ColumnInfo,
  createTableName,
  dropTable,
  type VisualExcelApacheTable,
  type VisualExcelSheet,
} from '~/app/components/visual-excel/db';
import type { VisualExcel } from '~/app/components/visual-excel/workbook';
import type { RequestOptions } from '~/data/dazzler-api';
import { queryKeyBuilder } from '~/data/utils/helpers/query-key.builder';
import { tableFromArrays } from 'apache-arrow';

export const visualExcelKeys = (() => {
  const base = 'visual-excel';

  return {
    base: () => queryKeyBuilder(base),
    workbook: (uri: string, reportFileId: string) => queryKeyBuilder(base, uri, 'reports', reportFileId),
    worksheet: (reportFileId: string, sheetName: string) => queryKeyBuilder(base, reportFileId, 'sheets', sheetName),
  };
})();

async function downloadVisualExcelWorkbook(
  uri: string,
  reportFileId: string,
  { signal }: RequestOptions,
): Promise<XLSX.WorkBook> {
  const endpoint = new URL(uri, baseURL);
  const res = await ky(endpoint, {
    headers: {
      Authorization: api.getAuthorizationHeader() ?? '',
    },
    retry: {
      limit: 3,
    },
    signal,
  });

  const arrayBuffer = await res.arrayBuffer();
  const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });

  const arrowTable: VisualExcelApacheTable = {
    id: [],
    sheet_name: [],
    rows: [],
    columns: [],
    column_info: [],
  };

  for (const [name, sheet] of Object.entries(workbook.Sheets)) {
    const range = utils.decode_range(sheet['!ref'] || 'A1');

    const cols: string[] = [];
    const colInfo: ColumnInfo[] = [];

    for (let i = 0; i <= range.e.c; i++) {
      const colKey = utils.encode_col(i);
      cols.push(colKey);

      // Get column width from sheet
      const excelColInfo = sheet['!cols']?.[i];
      const width = excelColInfo?.wpx ?? 0;

      colInfo.push({
        key: colKey,
        width: width,
      });
    }

    const data = utils.sheet_to_json<string>(sheet, {
      header: 1,
      defval: '',
      blankrows: true,
      raw: false,
    });
    const rows = data.map((row) => Array.from({ length: range.e.c + 1 }, (_, i) => row[i] || ''));

    arrowTable.id.push(window.crypto.randomUUID());
    arrowTable.sheet_name.push(name);
    arrowTable.rows.push(JSON.stringify(rows));
    arrowTable.columns.push(JSON.stringify(cols));
    arrowTable.column_info.push(JSON.stringify(colInfo));
  }

  const tableName = createTableName(reportFileId);
  await executeCompiledQuery(dropTable(db, tableName), connection);
  await connection.insertArrowTable(tableFromArrays(arrowTable), { name: tableName, create: true });
  // Write EOS
  // @ts-ignore
  await connection.insertArrowTable(EOS, { name: tableName });

  return workbook;
}

export function useListVisualExcelWorkbooks(
  visualExcels: VisualExcel[],
  options?: UseQueryOptions<{ workbook: XLSX.WorkBook; reportFileId: string }>,
) {
  return useQueries({
    queries: visualExcels.map((ve) => {
      return {
        async queryFn(ctx) {
          const workbook = await downloadVisualExcelWorkbook(ve.downloadURI, ve.reportFileId, ctx);
          return {
            workbook,
            reportFileId: ve.reportFileId,
          };
        },
        queryKey: visualExcelKeys.workbook(ve.downloadURI, ve.reportFileId),
        staleTime: hoursToMilliseconds(8),
        ...options,
      };
    }),
  });
}

export function useGetExcelSheet(name: string, reportFileId: string, options?: UseQueryOptions<VisualExcelSheet>) {
  return useQuery({
    async queryFn() {
      const tableName = createTableName(reportFileId);

      const query = db.selectFrom(tableName).selectAll().where('sheet_name', '=', name).limit(1).compile();

      const result = await executeCompiledQuery(query, connection);

      const sheet = result.toArray().map((row) => row.toJSON())[0] as VisualExcelSheet | undefined;
      if (!sheet) throw new Error(`could not find spreadsheet ${name} for visual excel ${reportFileId}`);

      return sheet;
    },
    queryKey: visualExcelKeys.worksheet(reportFileId, name),
    ...options,
  });
}
