import {
  Alert,
  AlertTitle,
  alpha,
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Paper,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Row from '~/ui/components/grid/row';
import { DataGrid, gridClasses, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { useDownloadComparisonVisualExcel, useGetComparison } from '~/data/comparisons/queries';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import { comparisonDetails as comparisonDetailsPath } from '~/app/constants/url/auditor';
import { ComparisonStatus, Severity } from '~/data/openapi-client/index';
import { BooleanParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { Show } from '~/app/components/core/show';
import ComparisonDetailsProgress from '~/app/components/pages/auditor/clients/client/report/report/ComparisonDetailsProgress';
import hoursToMilliseconds from 'date-fns/hoursToMilliseconds';
import { useDownloadComparisonRows, useFetchComparisonRows } from './hooks';
import * as db from './db';
import cx from '~/data/utils/helpers/cx';
import { useState } from 'react';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}`]: {
    '&.even': {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    '&.odd': {
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    '&.even .Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
    },
  },
}));

function ComparisonDetailsPage() {
  const { clientId, comparisonId } = useParams<{ clientId?: string; comparisonId?: string }>();
  const { data: comparisonDetails } = useGetComparison(clientId ?? '', comparisonId ?? '');
  const { isSuccess: isParquetLoaded, isInitialLoading: isParquetLoading } = useDownloadComparisonRows(
    clientId ?? '',
    comparisonId ?? '',
    {
      enabled: !!clientId && !!comparisonId && comparisonDetails?.status === ComparisonStatus.Success,
    },
  );

  const [downloadProgress, setDownloadProgress] = useState(0);

  const { mutate } = useDownloadComparisonVisualExcel(clientId ?? '', comparisonId ?? '');

  const [query, setQuery] = useQueryParams({
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, 10),
    sort: withDefault(StringParam, 'TableCode' as db.Column),
    direction: withDefault(StringParam, 'asc' as db.Direction),
    search: withDefault(StringParam, ''),
    filterColumn: withDefault(StringParam, ''),
    filterOperator: withDefault(StringParam, ''),
    filterValue: withDefault(StringParam, ''),
    numericValue: withDefault(BooleanParam, false),
  });

  const { offset, limit, sort, direction, search, filterColumn, filterOperator, filterValue, numericValue } = query;

  const filters = {
    offset,
    limit,
    sort: sort as db.Column,
    direction: direction as db.Direction,
    search,
    filterColumn,
    filterOperator,
    filterValue,
    numericValue,
  };

  const {
    data,
    isInitialLoading: isDataLoading,
    isSuccess: isRowsLoaded,
  } = useFetchComparisonRows(clientId!, comparisonId ?? '', filters as db.Filters, {
    keepPreviousData: true,
    staleTime: hoursToMilliseconds(8),
    enabled: isParquetLoaded,
  });

  const columns: GridColDef[] = [
    { field: 'TableCode', headerName: 'Table', flex: 150 },
    { field: 'RowCode', headerName: 'Row', flex: 150 },
    { field: 'ColumnCode', headerName: 'Column', flex: 150 },
    { field: 'ZAxis', headerName: 'zAxis', flex: 150 },
    { field: 'LeftValueParsed', headerName: comparisonDetails?.leftFile?.name ?? 'File 1', flex: 320, type: 'number' },
    {
      field: 'RightValueParsed',
      headerName: comparisonDetails?.rightFile?.name ?? 'File 2',
      flex: 320,
      type: 'number',
    },
    {
      field: 'RelativeDifference',
      headerName: 'Difference',
      flex: 200,
      type: 'number',
      cellClassName: 'font-bold',
      renderCell: ({ value }) => {
        const parsedValue = +value;
        return Number.isNaN(parsedValue) ? null : parsedValue.toFixed(2);
      },
    },
    {
      field: 'RelativeDifferencePercent',
      headerName: '%',
      flex: 150,
      type: 'number',
      renderCell: ({ value }) => {
        const percent = +value;
        return (
          <span
            className={cx(
              'font-bold',
              value > 0 && 'text-status-success',
              value < 0 && 'text-status-error',
              value == null && 'hidden',
            )}
          >
            {Number.isNaN(percent) ? null : `${percent.toFixed(2)}  %`}
          </span>
        );
      },
    },
  ];

  const rows: GridRowsProp =
    data?.items.map((item) => ({
      id: item.Id,
      TableCode: item.TableCode,
      RowCode: item.RowCode,
      ColumnCode: item.ColumnCode,
      ZAxis: item.ZAxis,
      LeftValueParsed: item.LeftValueParsed ?? item.LeftValueRaw,
      RightValueParsed: item.RightValueParsed ?? item.RightValueRaw,
      RelativeDifference: item.RelativeDifference ?? null,
      RelativeDifferencePercent: item.RelativeDifferencePercent ?? null,
    })) ?? [];

  return (
    <div>
      <Breadcrumbs.Anchor
        path={comparisonDetailsPath(clientId ?? '', comparisonId ?? '')}
        link={comparisonDetailsPath(clientId, comparisonId)}
        component={<span>Details</span>}
      />
      <div className="flex items-center justify-between">
        <div>
          <Row className="justify-between">
            <Typography fontSize={24} component="h1" className="text-dark">
              Compare files
            </Typography>
          </Row>
          <Typography fontSize={14} className="text-light">
            Comparison between 2 versions of the same type of report
          </Typography>
        </div>
        <Show when={comparisonDetails?.status === ComparisonStatus.Success}>
          <Button
            variant="outlined"
            size="large"
            className="relative"
            onClick={() => {
              mutate({
                onDownloadProgress: (progress) => {
                  if (progress.percent >= 1) {
                    setDownloadProgress(0);
                    return;
                  }
                  setDownloadProgress(progress.percent);
                },
              });
            }}
          >
            <div className="flex gap-1 items-center">
              <div
                className={cx(
                  'absolute w-full bottom-0 left-0 rounded-b-md',
                  downloadProgress > 0 && downloadProgress < 1 ? 'block' : 'hidden',
                )}
              >
                <LinearProgress value={downloadProgress * 100} variant="determinate" />
              </div>
              <Typography>Download Visual Excel</Typography>
            </div>
          </Button>
        </Show>
      </div>
      <div className="flex w-full mt-4 gap-4 items-center">
        <div className="basis-1/2">
          <Typography fontSize={14} className="text-light">
            File 1
          </Typography>
          <div className="p-5 bg-white rounded-md">
            <li className="bg-secondary-main justify-between items-center px-4 py-2 rounded-md grid grid-cols-[1fr_auto] w-full">
              <span className="truncate">{comparisonDetails?.leftFile?.name}</span>
            </li>
          </div>
        </div>
        <div className="basis-1/2">
          <Typography fontSize={14} className="text-light">
            File 2
          </Typography>
          <div className="p-5 bg-white rounded-md flex items-center gap-2">
            <li className="bg-secondary-main justify-between items-center px-4 py-2 rounded-md grid grid-cols-[1fr_auto] w-full">
              <span className="truncate">{comparisonDetails?.rightFile?.name}</span>
            </li>
          </div>
        </div>
      </div>
      <Show when={comparisonDetails?.status === ComparisonStatus.Processing}>
        <ComparisonDetailsProgress />
      </Show>
      <Show when={comparisonDetails?.status === ComparisonStatus.Error}>
        <Alert severity="error" className="mt-5 text-status-error">
          <AlertTitle>Processing comparison failed due to the following reasons:</AlertTitle>
          <ul>
            {Array.from(
              new Set(
                comparisonDetails?.processingLogs
                  ?.filter((log) => log.severity === Severity.Error)
                  .map((log) => log.message),
              ),
            ).map((msg) => (
              <li>{msg}</li>
            ))}
          </ul>
        </Alert>
      </Show>

      <Show when={comparisonDetails?.status === ComparisonStatus.Success}>
        <div style={{ height: 'auto', width: '100%', marginTop: 24 }}>
          <Paper classes={{ root: 'rounded-lg mt-8' }}>
            <Show
              when={isParquetLoaded}
              fallback={
                <Show when={isParquetLoading}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="10%">
                          <Typography>Table</Typography>
                        </TableCell>
                        <TableCell width="10%">
                          <Typography>Row</Typography>
                        </TableCell>
                        <TableCell width="10%">
                          <Typography>Column</Typography>
                        </TableCell>
                        <TableCell width="10%">
                          <Typography>zAxis</Typography>
                        </TableCell>
                        <TableCell width="22%">
                          <Typography>File 1</Typography>
                        </TableCell>
                        <TableCell width="22%">
                          <Typography>File 2</Typography>
                        </TableCell>
                        <TableCell width="16%">
                          <Typography>Difference</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        {new Array(limit).fill(undefined).map(() => (
                          <TableRow>
                            <TableCell width="10%">
                              <Skeleton variant="text" />
                            </TableCell>
                            <TableCell width="10%">
                              <Skeleton variant="text" />
                            </TableCell>
                            <TableCell width="10%">
                              <Skeleton variant="text" />
                            </TableCell>
                            <TableCell width="10%">
                              <Skeleton variant="text" />
                            </TableCell>
                            <TableCell width="22%">
                              <Skeleton variant="text" />
                            </TableCell>
                            <TableCell width="22%">
                              <Skeleton variant="text" />
                            </TableCell>
                            <TableCell width="16%">
                              <Skeleton variant="text" />
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    </TableBody>
                  </Table>
                </Show>
              }
            >
              <>
                <StripedDataGrid
                  key={+isRowsLoaded}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: sort, sort: direction as db.Direction }],
                    },
                    filter: {
                      filterModel: filterColumn
                        ? {
                            items: [
                              {
                                field: filterColumn,
                                operator: filterOperator,
                                value:
                                  filterOperator === 'isAnyOf'
                                    ? filterValue.length
                                      ? filterValue.split(',')
                                      : []
                                    : filterValue,
                              },
                            ],
                          }
                        : undefined,
                    },
                  }}
                  rowCount={Number(data?.count)}
                  pageSizeOptions={[5, 10, 25]}
                  loading={isDataLoading}
                  rows={rows}
                  columns={columns}
                  className="border-none"
                  paginationMode="server"
                  filterMode="server"
                  sortingOrder={['desc', 'asc']}
                  onSortModelChange={(model) => {
                    setQuery({
                      sort: model[0].field,
                      direction: direction === 'asc' ? 'desc' : 'asc',
                    });
                  }}
                  onFilterModelChange={(model) => {
                    const operator = model.items[0]?.operator;
                    const value = model.items[0]?.value;
                    const column = model.items[0]?.field;

                    const numericValue = [
                      'LeftValueParsed',
                      'RightValueParsed',
                      'RelativeDifference',
                      'RelativeDifferencePercent',
                    ].includes(column);

                    setQuery({
                      filterColumn: column ?? '',
                      filterOperator: operator ?? '',
                      filterValue: value ?? '',
                      numericValue,
                    });
                  }}
                  getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                  disableRowSelectionOnClick
                  hideFooter
                />

                <Show when={query.limit < (data?.count ?? 0)}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={Number(data?.count ?? 0)}
                    rowsPerPage={query.limit}
                    page={query.offset / query.limit}
                    onPageChange={(e, page) => {
                      setQuery({
                        offset: page * query.limit,
                      });
                    }}
                    onRowsPerPageChange={(e) => {
                      setQuery({
                        limit: +e.target.value,
                      });
                    }}
                  />
                </Show>
              </>
            </Show>
          </Paper>
        </div>
      </Show>
    </div>
  );
}

export default ComparisonDetailsPage;
