import ReportsTable from './ReportsTable';
import EditVatPopUp from '~/app/components/core/popup/edit-vat';
import ReportRemovePopup from '~/app/components/core/popup/report-remove';
import { actions, useDispatch, useSelector } from '~/data/index.js';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { clients, scopes, reports } from '~/data/selectors/index';
import UploadReportModal from './UploadReportModal';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { Direction } from '~/data/openapi-client';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { ScopeTypeParams } from '~/data/query-params';
import { reportKeys, useDeleteReport } from '~/data/reports/queries';
import { useGetClient } from '~/data/clients/queries';

const Reports = () => {
  const dispatch = useDispatch();

  const { clientId } = useParams<{ clientId: string }>();
  const { data: client } = useGetClient(clientId);

  const isClientLoaded = useSelector(clients.itemLoaded);
  const isScopesLoaded = useSelector(scopes.itemsLoaded);
  const reportsList = useSelector(reports.getItems);
  const isReportsLoaded = useSelector(reports.itemsLoaded);
  const totalItems = useSelector(reports.getTotalItems);
  const [reportRemovePopupState, setReportRemovePopupState] = useState(null);
  const [editVatPopupState, setEditVatPopupState] = useState(null);
  const [uploadPopupState, setUploadPopupState] = useState(false);

  const editClient = (values, formikActions) => dispatch(actions.clients.editClient(values, formikActions));
  const fetchClientScopes = () => dispatch(actions.scopes.fetchClientScopes(clientId, undefined));

  const queryClient = useQueryClient();

  const { mutate: deleteReport } = useDeleteReport(clientId, {
    onMutate: async ({ reportId }) => {
      await queryClient.cancelQueries({
        queryKey: reportKeys.reports(clientId),
        exact: false,
      });

      const previousData = queryClient.getQueryData(reportKeys.reports(clientId), {
        exact: false,
      });

      queryClient.setQueriesData({ queryKey: reportKeys.reports(clientId), exact: false }, (old) => {
        return {
          count: old.count - 1,
          items: old.items.filter((item) => item.id !== reportId),
        };
      });
      return { previousData };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueriesData({ queryKey: reportKeys.reports(clientId), exact: false }, context.previousData);
      queryClient.invalidateQueries({ queryKey: reportKeys.reports(clientId), exact: false, type: 'all' });
    },
    onSuccess: () => {
      toast.success('Report has been removed');
      setReportRemovePopupState(null);
    },
  });

  const [queryParams, setQueryParams] = useQueryParams({
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, 10),
    sort: withDefault(StringParam, 'Inserted'),
    direction: withDefault(StringParam, 'DESC' as Direction),
    scope: withDefault(ScopeTypeParams, undefined),
  });

  const { offset, limit, sort, direction, scope } = queryParams;

  const page = offset / limit;

  useEffect(() => {
    if (!isScopesLoaded && isReportsLoaded) {
      fetchClientScopes();
    }
  }, [isReportsLoaded]);

  const onFilter = (newScope) => {
    setQueryParams({ scope: newScope });
  };

  const onSort = (sort, direction) => {
    setQueryParams({ sort, direction });
  };

  const onRemove = (reportId: string) => {
    deleteReport({ reportId });
  };

  const handleEditClient = (vatNumber, formikActions) => {
    if (editClient({ clientId, vatNumber }, formikActions)) {
      setEditVatPopupState(null);
    }
  };

  const onPagination = (e) => {
    if (e.target.value) {
      setQueryParams({ limit: e.target.value });
    }
  };

  return (
    <>
      <ReportsTable
        queryParams={queryParams}
        totalItems={totalItems}
        reports={reportsList}
        client={client}
        onFilter={onFilter}
        onSort={onSort}
        sort={{ key: sort, direction }}
        page={page || 1}
        onPagination={onPagination}
        isClientLoaded={isClientLoaded}
        toggleReportRemovePopup={setReportRemovePopupState}
        toggleEditVatPopup={setEditVatPopupState}
        toggleUploadPopup={() => setUploadPopupState((prev) => !prev)}
        setQueryParams={setQueryParams}
      />
      {reportRemovePopupState && (
        <ReportRemovePopup
          report={reportRemovePopupState}
          closeFunction={() => setReportRemovePopupState(null)}
          onRemove={onRemove}
        />
      )}

      {editVatPopupState && (
        <EditVatPopUp client={client} editClient={handleEditClient} handleClose={() => setEditVatPopupState(null)} />
      )}
      <UploadReportModal
        open={uploadPopupState}
        onClose={() => setUploadPopupState(false)}
        maxWidth="xs"
        classes={{ paper: 'w-[351px] rounded-lg' }}
      />
    </>
  );
};

export default Reports;
