import React, { useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { onPaginationHelper } from '~/app/utils/pagination-helpers';
import { actions, selectors } from '~/data/index.js';
import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import Loader from '~/app/components/core/preloader/index';
import { ebaImportsPagePath } from '~/app/constants/url/auditor';
import { formatDate, formatTime } from '~/app/utils/format-data';
import { Text } from '~/ui/index.js';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';
import TablePagination from '~/ui/components/table-pagination/index';

import s from './styles.module.scss';
import ThreeDotsLoader from '~/ui/components/three-dots-loader/index';
import { useDownloadEBATransparencyImport } from '~/data/downloads/queries';

export const EbaImports = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { page } = useParams<{ page: string }>();

  const imports = useAppSelector(selectors.imports.getItems);
  const isImportsLoaded = useAppSelector(selectors.imports.itemsLoaded);
  const totalItems = useAppSelector(selectors.imports.getTotalItems);
  const uploading = useAppSelector(selectors.imports.getUploading);
  const uploaded = useAppSelector(selectors.imports.getUploaded);
  const downloading = useAppSelector(selectors.imports.getDownloading);

  const [perPage, setPerPage] = useState(10);
  const [file, setFile] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [currentlyDownloading, setCurrentlyDownloading] = useState(null);
  const [error, setError] = useState(false);

  const fetchImports = (filter) => dispatch(actions.imports.fetchList(filter));
  const clear = () => dispatch(actions.imports.clear());
  const upload = () => dispatch(actions.imports.upload(file, setError));

  const { mutate: download } = useDownloadEBATransparencyImport();

  const queryParams = {
    'paging.Limit': perPage,
    'paging.Offset': Number(page) > 1 ? (Number(page) - 1) * perPage : 0,
  };

  useEffect(() => {
    fetchImports(queryParams);
    return () => {
      clear();
    };
  }, [page, perPage, uploading]);

  const onPagination = (e) => {
    setPerPage(e.target.value);
    onPaginationHelper(e.target);
  };

  const onDownload = async (importId, filename) => {
    download({ importId, filename });
  };

  const onUpload = async () => {
    setShowLoader(true);
    const res = await upload();

    if (!res) {
      setFile(null);
      setShowLoader(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setError(false);
    const file1 = acceptedFiles[0];
    setFile(file1);
  }, []);

  useEffect(() => {
    if (uploaded && file !== null) {
      setFile(null);
      setShowLoader(false);
    }
  }, [uploaded, file]);

  const disabledDropzone = uploading || uploaded || !isImportsLoaded;

  if (isImportsLoaded && imports.size === 0 && Number(page) > 1) {
    return <Redirect to={ebaImportsPagePath(Math.ceil(totalItems / perPage))} />;
  }

  if (!isImportsLoaded || !imports) {
    return <Loader />;
  }

  return (
    <>
      <div className={s.imports}>
        <Box margin="32px 0 18px">
          <Text size={Text.size.s} weight={Text.weight.medium}>
            EBA Transparency Imports
          </Text>
        </Box>

        <Box className={s.importsWrap}>
          <Box>
            <Text size={Text.size.s} weight={Text.weight.medium}>
              File importing
            </Text>
          </Box>
          <Box paddingLeft="22px" width="40%">
            <Box marginTop="12px">
              <Dropzone
                onDrop={onDrop}
                minSize={1}
                maxSize={200582912}
                accept={{
                  'file/xbrl': ['.xbrl'],
                  'text/csv': [],
                }}
                multiple={false}
                disabled={showLoader}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps({
                        className: s.contentUploaderDropzone,
                        disabled: showLoader,
                      })}
                    >
                      <input {...getInputProps()} />
                      <Text size={Text.size.s}>
                        {file ? `Uploaded\n\n${file.name}` : 'Drag and drop or choose file'}
                      </Text>
                    </div>

                    {error && (
                      <Text size={Text.size.xxs} color={Text.color.burgundy}>
                        The current file cannot be loaded, please contact your system administrator or try another file.
                      </Text>
                    )}
                  </section>
                )}
              </Dropzone>
            </Box>
            <Box display="flex" justifyContent="flex-end" marginTop="24px">
              <Button variant="contained" disabled={!file || disabledDropzone} onClick={onUpload} loading={showLoader}>
                Submit
              </Button>
            </Box>
          </Box>
        </Box>

        <Box marginTop="20px">
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="25%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        File Name
                      </Text>
                    </TableCell>
                    <TableCell width="10%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        Status
                      </Text>
                    </TableCell>
                    <TableCell width="30%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        Created By
                      </Text>
                    </TableCell>
                    <TableCell width="20%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        Created Date
                      </Text>
                    </TableCell>
                    <TableCell width="15%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        Finished Time
                      </Text>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isImportsLoaded ? (
                    <>
                      {imports.size ? (
                        imports.map((ebaImport) => (
                          <TableRow key={ebaImport.get('id')}>
                            <TableCell width="25%">
                              <button
                                onClick={() => {
                                  setCurrentlyDownloading(ebaImport.get('id'));
                                  onDownload(ebaImport.get('id'), ebaImport.get('fileName'));
                                }}
                                type="button"
                                className={s.importsDownloadButton}
                              >
                                <Text
                                  size={Text.size.s}
                                  font={Text.color.colorTextMedium}
                                  weight={Text.weight.semiMedium}
                                >
                                  {ebaImport.get('fileName')}
                                </Text>
                                {downloading && ebaImport.get('id') === currentlyDownloading && (
                                  <ThreeDotsLoader.Small />
                                )}
                              </button>
                            </TableCell>
                            <TableCell width="10%">
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {ebaImport.get('status')}
                              </Text>
                            </TableCell>
                            <TableCell width="30%">
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {ebaImport.get('createdBy')}
                              </Text>
                            </TableCell>
                            <TableCell width="20%">
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {formatDate(ebaImport.get('inserted'))} {formatTime(ebaImport.get('inserted'))}
                              </Text>
                            </TableCell>
                            <TableCell width="15%">
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {ebaImport.get('finishedOn') && formatTime(ebaImport.get('finishedOn'))}
                              </Text>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Text>No files found</Text>
                      )}
                    </>
                  ) : (
                    <TableRowsLoader rowsNum={perPage} colsNum={5} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {isImportsLoaded && !!imports.size && (
              <div className={s.importsPagination}>
                <TablePagination
                  totalItems={totalItems}
                  perPage={perPage}
                  page={page}
                  handleChangePage={(p) => {
                    history.push(ebaImportsPagePath(p + 1));
                  }}
                  onRowsPerPageChange={onPagination}
                />
              </div>
            )}
          </Paper>
        </Box>
      </div>
    </>
  );
};
