import { createTheme, ThemeOptions } from '@mui/material/styles';

export const muiTheme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: '#c84800',
      light: '#FEDACC',
    },
    common: {
      white: '#ffffff',
    },
    secondary: {
      main: '#F3F3F3',
      light: '#696969',
      500: '#D1D1D1',
      400: '#474747',
    },
    info: {
      main: '#252525',
    },
  },

  typography: {
    fontFamily: [
      'Inter',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },

  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: `1px solid ${theme.palette.secondary[500]}`,
          height: 'auto',
          width: '100%',
          padding: '20px',
          overflow: 'hidden',
          boxShadow: 'none',

          '&.MuiPaper-elevation8': {
            width: 'auto',
            padding: '5px',
          },
        }),
      },
    },

    MuiTableContainer: {
      styleOverrides: {
        root: { maxHeight: 'fit-content' },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:nth-child(odd)': {
            backgroundColor: theme.palette.secondary.main,
          },
          cursor: 'default',
          '&.MuiTableRow-hover': {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.palette.primary.light,

              '& > td > span': {
                color: theme.palette.primary.main,
              },
            },
          },
        }),
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          '> tr.MuiTableRow-root': {
            backgroundColor: theme.palette.common.white,
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          border: 'none',
          padding: '8px',
          height: '44px',

          '&>.MuiButtonBase-root': {
            lineHeight: '1',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'capitalize',

          '& > span ': {
            color: 'inherit',
          },

          '&:disabled > span ': {
            color: 'inherit',
          },
        }),
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.common.white,

          '> .MuiInputBase-input.MuiOutlinedInput-input': {
            padding: '11px 32px 11px 11px',
          },
          '&.MuiInputBase-root.Mui-disabled': {
            cursor: 'not-allowed',
            opacity: '0.5',
          },
        }),
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          'label + &': {
            marginTop: '4px',
          },

          borderRadius: '4px',

          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
          },
        }),
        input: {
          padding: '12.5px 16px',
        },
      },
    },
    MuiDatePicker: {
      styleOverrides: {
        root: {
          '& .MuiFormHelperText-root': {
            display: 'none',
          },
          '& .MuiSvgIcon-root': {
            width: '20px',
            height: '20px',
          },
        },
      },
    },

    MuiPickersYear: {
      styleOverrides: {
        root: {
          '> .MuiPickersYear-yearButton': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      },
    },

    MuiPickersDay: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.secondary.light,
          fontSize: '10px',

          '&.Mui-selected, &:not(.Mui-selected)': {
            border: 'none',
          },
        }),
      },
    },

    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: { alignItems: 'center !important', marginLeft: 0, fontSize: '14px' },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderBottomWidth: '5px',
          backgroundColor: theme.palette.common.white,
        }),
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
});
