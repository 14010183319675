import history from '../history';

export const onFilterHelper = (key: string, value: string, id = '') => {
  const params = new URLSearchParams(window.location.search);
  params.set(`${id}filter`, key);
  params.set(`${id}search`, value);
  history.push({ search: params.toString() });
};

export const initFilter = (component: React.Component<any, any>, id = '') => {
  const params = new URLSearchParams(window.location.search);
  const s: any = component.state;
  if (params.get(`${id}filter`) && params.get(`${id}search`)) {
    s[params.get(`${id}filter`)] = params.get(`${id}search`);
  }
};
